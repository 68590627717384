import { Inject, Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import * as PModel from 'prosemirror-model';
import { uuidv4 } from "lib0/random";
import { Observable, Subscription, combineLatest } from "rxjs";

import { schema } from "@app/editor/utils/Schema";
import { articleSection, basicArticleSection } from "@app/editor/utils/interfaces/articleSection";
import { ServiceShare } from "@app/editor/services/service-share.service";
import { genereteNewReference } from '@app/layout/pages/library/lib-service/refs-funcs';
import { harvardStyle } from '@app/layout/pages/library/lib-service/csl.service';
import { figuresHtmlTemplate } from "../../figures-dialog/add-figure-dialog-v2/add-figure-dialog-v2.component";
import { getFilteredSectionChooseData, willBeMoreThan4Levels } from "@app/editor/utils/articleBasicStructure";
import { filterFieldsValues } from "@app/editor/utils/fieldsMenusAndScemasFns";
import { material } from "@app/core/services/custom_sections/material";
import { CiToTypes } from "@app/layout/pages/library/lib-service/editors-refs-manager.service";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIG, AppConfig } from "@app/core/services/app-config";
import { citationElementMap } from "@app/editor/services/citable-elements.service";
import { updateYFragment } from "../../../../y-prosemirror-src/plugins/sync-plugin.js";
import { getHtmlFromFragment } from "@app/editor/utils/prosemirrorHelpers";
import { DataPaperService } from "./data-paper.service";

@Injectable({
    providedIn: 'root'
  })
export class ImportJatsService {
    sections: basicArticleSection[] = [];
    DOMPMSerializer = PModel.DOMSerializer.fromSchema(schema);

    allJatsSections: {title: string, section: Element, secID?: string}[] = [];
    articleSections: articleSection[] = [];
    sectionsContent: { [key: string]: { 
        doc: PModel.Node | null,
        sectionTitle: string,
        level: number,
        secID: string,
        parsedSecTitle?: string,
        disabled?: boolean
    } } = {};

    citationLayoutControl = new UntypedFormControl("No brackets");
    selectedLayoutOption = "No brackets";
    
    layoutOptions = [];

    isChanging = true;
    subscription = new Subscription();

    constructor(
        private serviceShare: ServiceShare, 
        private httpClient: HttpClient,
        private dataPaperService: DataPaperService, 
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {}

    parseXML (doc: Document, sections: basicArticleSection[], articleStructureFlat: string[]) {
        return new Promise<{ sectionsContent: { [key: string]: { 
            doc: PModel.Node,
            sectionTitle: string,
            level: number,
            secID: string,
            parsedSecTitle?: string,
            disabled?: boolean
         } }, 
         allJatsSections: {title: string, section: Element}[] }>((resolve, reject) => {
            const citationOptions = this.serviceShare.YdocService.articleData.layout.settings["allowed_tags"]?.cslCitationOptions?.layoutOptions;
            this.subscription.add(this.citationLayoutControl.valueChanges.subscribe((value: string) => {
                this.selectedLayoutOption = value;
            }));

            if(citationOptions) {
                Object.keys(citationOptions).forEach((key: string, i: number) => {
                  this.layoutOptions.push({ name: key, layout: citationOptions[key] });
                })
            }
            // console.log(doc);
            this.taxonData = {};
            this.materialsData = [];
            this.allJatsSections = [];
            this.sectionsContent = {};
            this.sections = sections;
            this.articleSections = articleStructureFlat.map((secID: string) => this.serviceShare.YdocService.getSectionByID(secID)).filter((sec: articleSection) => sec.title.name != "[AM] Funder");
            this.articleSections.forEach((sec => {
                this.sectionsContent[sec.sectionID] = {
                    sectionTitle: sec.title.label?.match(/<[^>]*>/g) ? sec.title.label.replace(/<[^>]*>/g, '').trim() : sec.title.label,
                    doc: null,
                    level: this.serviceShare.TreeService.getNodeLevel(sec).hTag,
                    secID: sec.sectionID
                };
            }))
            
            const front = doc.querySelector("front");
            const body = doc.querySelector("body");
            const back = doc.querySelector("back");
            const floatsGroup = doc.querySelector("floats-group");
            
            const figGroups = floatsGroup?.querySelectorAll("fig-group")?.length > 0 ? floatsGroup?.querySelectorAll("fig-group") : floatsGroup?.querySelectorAll("fig") || doc.querySelectorAll("fig");
            const tables = floatsGroup ? floatsGroup.querySelectorAll("table-wrap") : null; // get tables from floatsGroup, because some sections' content is displayed as table
            const supplementaryFiles = floatsGroup?.querySelectorAll("supplementary-material");
            if(figGroups) {
                this.parseFigures(Array.from(figGroups));
            }
            if(tables) {
                this.parseTables(Array.from(tables));
            }
            if(supplementaryFiles) {
                this.parseSupplementaryMaterials(Array.from(supplementaryFiles));
            }
            this.addContentToFigures();
            this.addContentToTables();
            this.addContentToSupplementaryMaterials();

            const parseFunc = () => {
                setTimeout(() => {
                    if(front) {
                        this.parseFront(front);
                    }
                    if(body) {
                        this.parseBody(body);
                    }
                    setTimeout(() => {
                        resolve({ sectionsContent: this.sectionsContent, allJatsSections: this.allJatsSections })
                    }, 5000);
                }, 100)
            }

            let isParsed = false;

            if(back) {
                this.parseBack(back);

                if(this.referencesThatShouldBeFetched.length > 0) {
                    combineLatest(this.referencesThatShouldBeFetched.map(ref => ref.obs)).subscribe(({
                        next: (response: any[]) => {
                            response.forEach((r: any, i: number) => {
                                if(this.referencesThatShouldBeFetched[i].doi) {
                                    let parsedJson = JSON.parse(r);
                                    if(parsedJson.mapedReferences.length > 0) {
                                        const ref = parsedJson.mapedReferences.find((ref: any) => ref.ref["DOI"] == this.referencesThatShouldBeFetched[i].doi);
                                        if(ref) {
                                            this.parseRefFromRefindid(ref);
                                        } else {
                                            const text = this.referencesThatShouldBeFetched[i].refText;
                                            this.serviceShare.RefsApiService.parseReferenceFromPlainText(text).subscribe(res => {
                                                if (res) {
                                                    this.initializeReferenceWithStyleAndCitation(res.type, res.id, res);
                                                } else {
                                                    // If we don't manage to import the reference by doi or with parse service,
                                                    // create an empty ref object with the reference text as title
                                                    // so the user can edit it manually
                                                    this.initializeReferenceWithStyleAndCitation('article', uuidv4(), { title: text });
                                                }
                                            })
                                        }
                                    }
                                } else { // ref object from parse service
                                    if (r) {
                                        this.initializeReferenceWithStyleAndCitation(r.type, r.id, r);
                                    } else {
                                        const text = this.referencesThatShouldBeFetched[i].refText;
                                        this.initializeReferenceWithStyleAndCitation('article', uuidv4(), { title: text });
                                    }
                                }
                            })

                        },
                        error: (err) => {
                            console.error(err)
                        },
                        complete: () => {
                            parseFunc();
                        }
                    }))
                } else {
                    parseFunc();
                }
            } else {
                parseFunc();
            }
        })
    }

    parseFront(front: Element) {
        const articleTitle = front.querySelector("title-group > article-title");
        const abstract = front.querySelector("abstract");
        const keywords = front.querySelector("kwd-group");
        const notes = front.querySelector("notes");
    
        const titleSec = this.articleSections.find(sec => sec.title.name == "[AM] Title");
        const abstractSec = this.articleSections.find(sec => sec.title.name == "[AM] Abstract");
        const keywordsSec = this.articleSections.find(sec => sec.title.name == "[AM] Keywords");
    
        if(articleTitle && articleTitle.innerHTML && titleSec) {
            const originalContent = this.serviceShare.ProsemirrorEditorsService.editorContainers[titleSec.sectionID].editorView.state.doc.textContent;
            this.allJatsSections.push({ title: articleTitle.textContent.replace(/<[^>]*>/g, '').trim(), section: articleTitle });
            this.sectionsContent[titleSec.sectionID] = {
                sectionTitle: originalContent,
                doc: null,
                parsedSecTitle: articleTitle.textContent.replace(/<[^>]*>/g, '').trim(),
                level: 1,
                secID: titleSec.sectionID
            };
        }
        
        if(abstract && abstractSec) {
            this.iterateSections(abstractSec, abstract, 2);
        }
    
        if(keywords && keywordsSec) {
            this.allJatsSections.push({ title: keywords.children[0].textContent.replace(/<[^>]*>/g, '').trim(), section: keywords });
            this.sectionsContent[keywordsSec.sectionID] = {
                sectionTitle: keywordsSec.title.label,
                doc: null,
                parsedSecTitle: keywords.children[0].textContent.replace(/<[^>]*>/g, '').trim(),
                level: 2,
                secID: keywordsSec.sectionID
            };
        }
    
        if(notes) {
            Array.from(notes.children).forEach(child => {
                let section: articleSection;
                let isChildSec: boolean;
                const findSection = (sections: basicArticleSection[], searched: string, isChild: boolean) => {
                    sections.forEach(sec => {
                        if(sec.name.includes(searched) || sec.label.includes(searched)) {
                            section = this.serviceShare.YdocService.getSectionByID(sec.sectionID);
                            isChildSec = isChild;
                        }
                        if(!section) {
                            findSection(sec.children, searched, true);
                        }
                    })
                }
                findSection(this.sections, child.children[0].textContent.trim(), false);
    
                if(section) {
                    let hTag = isChildSec ? 3 : 2;
                    this.iterateSections(section, child, hTag);
                }
            })
        }
    }

    parseBody(body: Element) {
        Array.from(body.children).forEach(child => {
            let section = this.sections.find(sec => sec.label.includes(child.children[0].textContent.trim()) || sec.name.includes(child.attributes.getNamedItem("sec-type")?.value?.trim()));
            const articleSection = this.serviceShare.YdocService.getSectionByID(section?.sectionID);
            if(section && section.name == "[MM] Taxon treatments") {
                const articleSection = this.serviceShare.YdocService.getSectionByID(section.sectionID);
                this.parseTaxonTreatments(articleSection, child);
            } else if(section && section.name == "[PS] Data resources") {
                const articleSection = this.serviceShare.YdocService.getSectionByID(section.sectionID);
                this.parseDataResources(articleSection, child);
            } else if(section && section.name == "[PS] Collection data") {
                const articleSection = this.serviceShare.YdocService.getSectionByID(section.sectionID);
                this.parseCollectionData(articleSection, child);
            } else {
                if(section) {
                    this.iterateSections(articleSection, child, 2);
                } else {
                    this.allJatsSections.push({ title: child.children[0].textContent.replace(/<[^>]*>/g, '').trim(), section: child });
                    const findAllChildren = (children: Element[]) => {
                        children.forEach(ch => {
                            if(ch.nodeName == "sec") {
                                this.allJatsSections.push({ title: ch.children[0].textContent.replace(/<[^>]*>/g, '').trim(), section: ch });
                                if(ch.children.length) {
                                    findAllChildren(Array.from(ch.children))
                                }
                            }
                        })
                    }
                    findAllChildren(Array.from(child.children));
                }
            }
        })
    }

    endNotesNumbers: any = [];
    endNotes: any = {};

    parseBack(back: Element) {
        this.endNotesNumbers = this.serviceShare.YdocService.endNotesMap.get("endNotesNumbers") || [];
        this.endNotes = this.serviceShare.YdocService.endNotesMap.get("endNotes") || {};
        Array.from(back.children).forEach(child => {
            if(child.nodeName == "sec") {
                this.allJatsSections.push({ title: child.children[0].textContent.replace(/<[^>]*>/g, '').trim(), section: child })
                let section: articleSection;
                let hTag = 2;
    
                const findSection = (sections: basicArticleSection[], searched: string, level: number) => {
                    sections.forEach(sec => {
                        if(sec.name.includes(searched) || sec.label.includes(searched)) {
                            const articleSection = this.serviceShare.YdocService.getSectionByID(sec.sectionID);
                            section = articleSection;
                            hTag = level;
                        }
                        if(!section) {
                            findSection(sec.children, searched, level + 1);
                        }
                    })
                }
                findSection(this.sections, child.children[0].textContent.trim(), 2);
                
                if(section) {
                    this.iterateSections(section, child, hTag);
                }
            } else if (child.nodeName == "ref-list") {
                Array.from(child.children).forEach((el: Element) => {
                    const personGroups = Array.from(el.querySelectorAll("person-group"));
                    this.parseReferences(el, personGroups);
                })
                this.refsObj = this.serviceShare.CslService.sortCitations(this.refsObj);
            } else if (child.nodeName == "fn-group") {
                const footNotes = child.querySelectorAll("fn");
                Array.from(footNotes).forEach((el, i) => {
                    const jats_id = el.id;
                    Array.from(el.children).forEach(ch => {
                        if(ch.nodeName == "p") {
                            const description = ch?.childNodes ? Array.from(ch.childNodes).map(c => this.createProsemirrorNodes(c.nodeName, c.textContent, c)).flat() : [];
                            const content = description.length > 0 ? getHtmlFromFragment(PModel.Fragment.from(description), this.DOMPMSerializer) : "";
                            const endNote = {
                                jats_id,
                                "endNote": content,
                                "end_note_number": this.endNotesNumbers.length + i,
                                "end_note_ID": uuidv4(),
                            }
                            this.endNotes[endNote.end_note_ID] = endNote;
                            this.endNotesNumbers.push(endNote.end_note_ID);
                        }
                    })
                })
            }
        })
    }
    
    createProsemirrorNodes(elType: string, content: string, element: ChildNode, isPreview?: boolean) {
        if(elType != "kwd" && !content.includes(",")) {
            content = content.trim();
        }
        if(content == "") {
            return [];
        }
        
        switch(elType) {
            case "#text": {
                return [schema.text(content)];
            }
            case "bold": {
                return [schema.text(" "), schema.text(content, [schema.marks.strong.create({}), ...Array.from(element.childNodes).map(ch => this.createProsemirrorMark(ch.nodeName, ch)).filter(m => m != undefined && m?.type.name == "text")])];
            }
            case "sup": {
                return [schema.text(" "), schema.text(content, [schema.marks.superscript.create({}), ...Array.from(element.childNodes).map(ch => this.createProsemirrorMark(ch.nodeName, ch)).filter(m => m != undefined && m?.type.name == "text")]), schema.text(" ")];
            }
            case "ext-link": {
                //@ts-ignore
                const attrs = element.attributes;
                const href = attrs.getNamedItem("xlink:href")?.value || "";
                
                return [schema.text(" "), schema.text(content, [schema.marks.link.create({
                    href,
                    title: "",}) , ...Array.from(element.childNodes).map(ch => this.createProsemirrorMark(ch.nodeName, ch)).filter(m => m != undefined && m?.type.name == "text")]), schema.text(" ")];
            }
            case "italic": {
                return [schema.text(" "), schema.text(content, [schema.marks.em.create({}), ...Array.from(element.childNodes).map(ch => this.createProsemirrorMark(ch.nodeName, ch)).filter(m => m != undefined && m?.type.name == "text")]), schema.text(" ")];
            }
            case "tp:taxon-name": {
                return isPreview ? [schema.text(" "), schema.text(content, [schema.marks.taxon.create({removedtaxon: false, taxmarkid: uuidv4()}), ...Array.from(element.childNodes).map(ch => this.createProsemirrorMark(ch.nodeName, ch, isPreview)).filter(m => m != undefined && m?.type.name == "text")]), schema.text(" ")] : [schema.text(content)];
            }
            case "xref": {
                //@ts-ignore
                const type = element.attributes.getNamedItem("ref-type")?.value;
                //@ts-ignore
                const nums = element.attributes.getNamedItem("rid")?.value?.split(",");
                
                if(type == "fig") {
                    const figures = this.figures;
                    const citated_elements = [];
                    const indexes = [];
                    Object.entries(figures as {[id: string]: any}).forEach(([id, value], index) => {
                        if(nums.includes(value.jats_id)) {
                            citated_elements.push(id);
                            indexes.push(index + 1);
                        }
                    })

                    if(citated_elements.length == 0) return [schema.text(content)];
                    const citationText = citated_elements.length > 1 ? ` ${indexes.join(", ")} ` : ` ${indexes.join(", ")} `;

                    return [schema.text(citationText, [schema.mark("citation", {
                        isFromImport: true,
                        citated_elements,
                        "citateid": uuidv4()
                      })])];
                } else if (type == "table") {
                    const tables = this.tables;
                    const citated_elements = [];
                    const indexes = [];
                    Object.entries(tables as {[id: string]: any}).forEach(([id, value], index) => {
                        if(nums.includes(value.jats_id)) {
                            citated_elements.push(id);
                            indexes.push(index + 1);
                        }
                    })

                    if(citated_elements.length == 0) return [schema.text(content)];
                    
                    const citationText = citated_elements.length > 1 ? ` ${indexes.join(", ")} ` : ` ${indexes.join(", ")} `;
                    return [schema.text(citationText, [schema.mark("table_citation", {
                        isFromImport: true,
                        citated_elements,
                        "citateid": uuidv4()
                      })])];
                } else if (type == "fn") {
                    const footNotes = this.endNotes;
                    const citated_elements = [];
                    const indexes = [];
                    Object.entries(footNotes as {[id: string]: any}).forEach(([id, value], index) => {
                        if(nums.includes(value.jats_id)) {
                            citated_elements.push(id);
                            indexes.push(index + 1);
                        }
                    })

                    if(citated_elements.length == 0) return [schema.text(content)];
                    
                    const citationText = citated_elements.length > 1 ? `*${indexes.join(", ")}` : `*${indexes.join(", ")}`;
                    return [schema.text(citationText, [schema.mark("end_note_citation", {
                        citated_elements,
                        "citateid": uuidv4()
                      })])];
                } else if (type == "supplementary-material") {
                    const suppFiles = this.supplementaryFiles;
                    const citated_elements = [];
                    const indexes = [];
                    Object.entries(suppFiles as {[id: string]: any}).forEach(([id, value], index) => {
                        if(nums.includes(value.jats_id)) {
                            citated_elements.push(id);
                            indexes.push(index + 1);
                        }
                    })

                    if(citated_elements.length == 0) return [schema.text(content)];
                    
                    const citationText = citated_elements.length > 1 ? ` ${indexes.join(", ")} ` : ` ${indexes.join(", ")} `;
                    return [schema.text(citationText, [schema.mark("supplementary_file_citation", {
                        isFromImport: true,
                        citated_elements,
                        "citateid": uuidv4()
                      })])];
                } else if (type == "bibr") {
                    const refMap = this.refsObj;

                    const citedRefsIds = [];
                    const neededRefsForCitation = {};

                    Object.entries(refMap as {[id: string]: any}).forEach(([id, value]) => {
                        if(nums.includes(value.jats_id)) {
                            citedRefsIds.push(id);
                            neededRefsForCitation[id] = value;
                        }
                    })

                    if(citedRefsIds.length == 0) return [schema.text(content)];

                    const refCitationID = uuidv4();

                    const layoutOption = this.layoutOptions.find(opt => opt.name == this.selectedLayoutOption);
                    const citationSettings = { 
                        selectedSortOptions: [["Default"]],
                        layout: layoutOption ? layoutOption.layout.join("\n") : this.layoutOptions[0].layout.join("\n")
                    }
                    const sortedCitationStrings = this.serviceShare.CslService.generateCitation(neededRefsForCitation, citationSettings);

                    this.referenceCitations[refCitationID] = {
                        text: sortedCitationStrings.text,
                        refCitationIDs: citedRefsIds,
                        citationLayout: this.layoutOptions.find(opt => opt.name == this.selectedLayoutOption) || this.layoutOptions[0], //?  citationOptions?.["No brackets"] ? {name: 'No brackets', layout: citationOptions?.["No brackets"]} : {name: "Default", layout: ['Default']},
                        sortOptions: [{name: "Default", tag: "Default"}]
                    }
                    
                    const tooltip = citedRefsIds.map(id => `${refMap[id].citation.textContent?.trim()}\nCiTO: ${refMap[id].refCiTO?.label || CiToTypes[0].label}`).join("\n");
                    let nodeAttrs = {
                        refCitationID,
                        citedRefsIds,
                        contenteditableNode: false,
                        citedRefsCiTOs: ["None"],
                        tooltip
                    }

                    const mark = schema.mark("reference_citation", nodeAttrs);
                    return [schema.text(sortedCitationStrings.text, [mark])];
                }
                return []
            }
            case "list-item": {
                const result = [];
                Array.from(element.childNodes).forEach(el => {
                    result.push(...this.createProsemirrorNodes(el.nodeName, el.textContent, el).filter(c => c != undefined))
                })
                return [schema.nodes.list_item.create({}, result)];
            }
            case "p": {
                const result = [];
                Array.from(element.childNodes).forEach(el => {
                    result.push(...this.createProsemirrorNodes(el.nodeName, el.textContent, el).filter(c => c != undefined))
                })
                return [schema.nodes.paragraph.create({}, result)];
            }
            case "list": {
                const result = this.parseSectionContent(element as Element);
                return result
            }
            default: {
                const marks = Array.from(element.childNodes).map(ch => this.createProsemirrorMark(ch.nodeName, ch));
                return [schema.text(content, marks.filter(m => m != undefined && m?.type.name != "text"))];
            }
        }
    }

    createProsemirrorMark(elType: string, element: ChildNode, isPreview?: boolean) {
        switch(elType) {
            case "bold": {
                return schema.marks.strong.create({});
            }
            case "sup": {
                return schema.marks.superscript.create({});
            }
            case "ext-link": {
                //@ts-ignore
                const attrs = element.attributes;
                const href = attrs.getNamedItem("xlink:href")?.value || "";
                
                return schema.marks.link.create({ href, title: "" });
            }
            case "italic": {
                return schema.marks.em.create({});
            }
            case "tp:taxon-name": {
                return isPreview ? schema.marks.taxon.create({removedtaxon: false, taxmarkid: uuidv4()}) : undefined;
            }
        }
    }
    titleTags = ["title", "label"];
    iterateSections (sec: articleSection, el: Element, level: number) {
        this.allJatsSections.push({ title: this.titleTags.includes(el.children[0].tagName) ? el.children[0].textContent.replace(/<[^>]*>/g, '').trim() : "No Title", section: el, secID: sec.sectionID });
        this.sectionsContent[sec.sectionID] = {
            doc: null,
            sectionTitle: sec.title.label?.match(/<[^>]*>/g) ? sec.title.label.replace(/<[^>]*>/g, '').trim() : sec.title.label,
            parsedSecTitle: this.titleTags.includes(el.children[0].tagName) ? el.children[0].textContent.replace(/<[^>]*>/g, '').trim() : "No Title",
            level:  this.sectionsContent[sec.sectionID]?.level || this.serviceShare.TreeService.getNodeLevel(sec).hTag,
            secID: sec.sectionID
        };

        let hasChildren = false;
    
        Array.from(el.children).forEach(child => {
            if(child.nodeName == "sec") {
                if(sec.type == "simple") {
                    this.allJatsSections.push({ title: child.children[0].textContent.replace(/<[^>]*>/g, '').trim(), section: child })
                } else {
                    hasChildren = true;
                }
            } 
        })

        if(hasChildren && sec.type == "complex") {
            const secChildren = el.querySelectorAll("sec");
            secChildren.forEach((c, i) => {
                if(sec.children[i]) {
                    this.iterateSections(sec.children[i], c, level + 1);
                } else {
                    let fileredSections = getFilteredSectionChooseData(this.serviceShare.TreeService.findNodeById(sec.sectionID), this.serviceShare.TreeService);
                    const section = fileredSections.find(sec => sec.source == "template");
    
                    if(section) {
                        const newSection = this.serviceShare.TreeService.addNodeAtPlaceChange(sec.sectionID, section.template, "end");
                        setTimeout(() => {
                            this.iterateSections(newSection, c, level + 1);
                        }, 200);
                    } else {
                        this.serviceShare.ArticleSectionsService.getSectionById(fileredSections[0].id).subscribe((res: any) => {
                            if(res.data.id == sec.id && !res.data.pivot_id) {
                              res.data.pivot_id = sec.pivotId;
                            }
                            res.data.parent = sec;
                            let { nodeLevel } = this.serviceShare.TreeService.getNodeLevel(sec);
                            let node: articleSection
                            if(!willBeMoreThan4Levels(nodeLevel + 1, res.data)){
                              node = this.serviceShare.TreeService!.addNodeAtPlaceChange(sec.sectionID, res.data, "end");
                            }
                            setTimeout(() => {
                                if(node) {
                                    this.iterateSections(node, c, level + 1);
                                }
                            }, 200);
                        })
                    }
                }
            })
        }
    }

    parseSectionContent(child: Element, isPreview?: boolean, shouldPreserveSpaces?: boolean) {
        const children = [];
        child.childNodes.forEach(ch => {
            children.push(...this.createProsemirrorNodes(ch.nodeName, shouldPreserveSpaces ? ch.textContent : ch.textContent.split("\n").join(" "), ch, isPreview));
        })
        if(child.nodeName == "list") {
            let node: PModel.Node;
            if(child.attributes.getNamedItem("list-type").value == "order") {
                node = schema.nodes.ordered_list.create({}, children.filter(c => c != undefined));            
                return [node];
            } else {
                node = schema.nodes.bullet_list.create({}, children.filter(c => c != undefined));
                return [node];
            }
        } else if (child.nodeName == "p") {
           return [schema.nodes.paragraph.create({}, children.filter(c => c != undefined))];
        } else if (child.nodeName == "preformat") {
           return [schema.nodes.code_block.create({}, children.filter(c => c != undefined))];
        } else {
           return children;
        }
    }

    parseRefFromRefindid(ref: any) {
        if(!ref.ref.id){
            ref.ref.id = uuidv4()
        }
        let refStyle: any;

        if (
            this.serviceShare.YdocService.articleData &&
            this.serviceShare.YdocService.articleData.layout.citation_style) {
            let style = this.serviceShare.YdocService.articleData.layout.citation_style
            refStyle = {
              "name": style.name,
              "label": style.title,
              "style": style.style_content,
              "last_modified": (new Date(style.style_updated).getTime())
            };
          } else {
            refStyle = {
              "name": "harvard-cite-them-right",
              "label": "Harvard Cite Them Right",
              "style": harvardStyle,
              "last_modified": 1649665699315
            };
          }
        let refBasicCitation:any = this.serviceShare.CslService.getBasicCitation(ref.ref, ref.style);
        
        let container = document.createElement('div');
        container.innerHTML = refBasicCitation.bibliography;
        refBasicCitation.textContent = container.textContent;
        let refInstance = {
            ...ref,
            citation: refBasicCitation,
            // refType: result.referenceScheme,
            refType: {
                "last_modified": Date.now()
            },
            ref_last_modified:Date.now(),
            // refCiTO:result.refCiTO,
            refStyle
        }

        this.refsObj[ref.ref.id] = refInstance;
    }

    initializeReferenceWithStyleAndCitation(type: string, id: string, data: any) {
        const newRef = genereteNewReference({ type }, data);
        let refObj = { ref: newRef};
        let refStyle: any;
        if (
            this.serviceShare.YdocService.articleData &&
            this.serviceShare.YdocService.articleData.layout.citation_style
        ) {
            let style = this.serviceShare.YdocService.articleData.layout.citation_style
            refStyle = {
                "name": style.name,
                "label": style.title,
                "style": style.style_content,
                "last_modified": (new Date(style.style_updated).getTime())
            }
        } else {
            refStyle = {
                "name": "harvard-cite-them-right",
                "label": "Harvard Cite Them Right",
                "style": harvardStyle,
                "last_modified": 1649665699315
            }
        }
        let refBasicCitation: any = this.serviceShare.CslService.getBasicCitation(refObj.ref, refStyle.style);
        let container = document.createElement('div');
        container.innerHTML = refBasicCitation.bibliography;
        refBasicCitation.textContent = container.textContent;
        let refInstance = {
            jats_id: id,
            ...refObj,
            citation: refBasicCitation,
            ref_last_modified: Date.now(),
            refStyle,
            formIOData: data,
            refType: {
                name: type,
                type: type,
                "last_modified": Date.now()
            }
        }
    
        this.refsObj[newRef.id] = refInstance;
    }

    /**
     * Formats the text content of a `mixedCitation` element to improve parsing of authors.
     * - Adds commas after `<name>` elements.
     * - Inserts spaces between consecutive element nodes.
     * 
     * Example author format:
     * ```
     * <person-group person-group-type="author">
     *     <name name-style="western">
     *         <surname>Zapparoli</surname>
     *         <given-names>M.</given-names>
     *     </name>
     * </person-group>
     * ```
     */
    getMixedCitationText = (mixedCitation: Element): string => {
        const textParts: string[] = [];

        const loopNode = (node: Node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                textParts.push(node.textContent.trim());
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                Array.from(node.childNodes).forEach((child, index, array) => {
                    loopNode(child);
    
                    // Check for consecutive element nodes
                    if (index + 1 < array.length && array[index + 1].nodeType === Node.ELEMENT_NODE) {
                        // Add a comma after <name> elements, otherwise add a space
                        const currentIsName = node.childNodes[index].nodeName.toLocaleLowerCase() === 'name';
                        if (currentIsName) {
                            textParts.push(", ");
                        } else {
                            textParts.push(" ");
                        }
                    }
                });
            }
        };
    
        loopNode(mixedCitation);
    
        return textParts.join('').trim();
    };

    refsObj: any = {};
    referenceCitations: any = {};
    referencesThatShouldBeFetched: {obs: Observable<any>, doi?: string, refText?: string}[] = [];

    parseReferences(child: Element, personGroups: Element[]) {
        this.refsObj = this.serviceShare.YdocService.referenceCitationsMap.get("refsAddedToArticle") || {};
        this.referenceCitations = this.serviceShare.YdocService.referenceCitationsMap.get('referenceCitations') || {};
        /**
         * <mixed-citation>
            Poelen, Jorrit H., James D. Simons, and Chris J. Mungall. 2014. “Global Biotic Interactions: An Open Infrastructure to Share and Analyze Species-Interaction Datasets.”
            <italic>Ecological Informatics</italic>
            24 (November): 148–59.
            <ext-link ext-link-type="uri" xlink:href="https://doi.org/10.1016/j.ecoinf.2014.08.005">https://doi.org/10.1016/j.ecoinf.2014.08.005</ext-link>
            .
           </mixed-citation>
         */
        if(child.nodeName == "ref") {
            const mixedCitation = child.querySelector("mixed-citation");
            if(mixedCitation) {
                const citationText = this.getMixedCitationText(mixedCitation);
                // doi matching pattern -> https://stackoverflow.com/questions/27910/finding-a-doi-in-a-document-or-page
                const pattern = /\b10\.[0-9]{4,}(?:\.[0-9]+)*\/([^"\'&<>]+)\b/;
                const match = citationText.match(pattern);
                
                if(match) {
                    this.referencesThatShouldBeFetched.push({
                        obs: this.httpClient.get(this.config.externalRefsApi, {
                        responseType: 'text',
                        params: {
                                search: 'simple',
                                text: match[0],
                                db: ["crossref", "datacite", "pubmed", "gnub"]
                            }
                        }),
                        doi: match[0],
                        refText: citationText.replace(/<[^>]+>/g, ''),
                    });
                } else {
                    const text = citationText.replace(/<[^>]+>/g, '');
                    this.referencesThatShouldBeFetched.push({
                        obs: this.serviceShare.RefsApiService.parseReferenceFromPlainText(text),
                        refText: text,
                    });
                }
            } else {
                let id = child.id;
                const type = child.querySelector("element-citation, nlm-citation")?.attributes?.getNamedItem("publication-type")?.value || "";
                const authors = [];
                const editors = [];
                personGroups?.forEach(pGroup => {
                    let typeOfGroup = pGroup.attributes.getNamedItem("person-group-type")?.value;
                    if(typeOfGroup == "guest-editor") {
                        typeOfGroup = "contributor";
                    }
                    Array.from(pGroup.children).forEach(ch => {
                        if(ch.nodeName == "name") {
                            const person = {
                                last: ch.querySelector("surname")?.textContent.trim() || "",
                                first: ch.querySelector("given-names")?.textContent.trim() || "",
                                name: "",
                                role: typeOfGroup,
                                type: "person"
                            };
                            if (typeOfGroup == 'editor') {
                                editors.push(person);
                            } else {
                                authors.push(person);
                            }
                        }
                    })
                })

                const queryTextContent = (element: Element, selector: string) => {
                    if (!selector || !element) return "";
                    return element.querySelector(selector)?.textContent?.trim() || "";
                }

                const queryAttribute = (element: Element, selector: string, attr: string) => {
                    if (!selector || !element || !attr) return "";
                    return element.querySelector(selector)?.attributes?.getNamedItem(attr)?.value || "";
                }
        
                const getTitle = () => {
                    const titleMappings = {
                        'article': "article-title",
                        'article-journal': "article-title",
                        'software': "article-title",
                        'webpage': "article-title",
                        'website': "article-title",
                        'paper-conference': "article-title",
                        'book': "source",
                        'thesis': "source",
                        'chapter': "chapter-title",
                        "other": "article-title",
                    };
                    return queryTextContent(child, titleMappings[type] || "");
                };
        
                const getPages = () => {
                    const firstPage = queryTextContent(child, "fpage");
                    const lastPage = queryTextContent(child, "lpage");
                    const size = queryTextContent(child, 'size[units="page"]');
                    return firstPage && lastPage ? `${firstPage}-${lastPage}` : size;
                };

                const getDOI = () => {
                    const doiPattern = /\b10\.[0-9]{4,}(?:\.[0-9]+)*\/([^"\'&<>]+)\b/;
                    const element = child.querySelector("ext-link, pub-id");
                    const match = element?.textContent.match(doiPattern);
                    return match ? element.textContent : "";
                };

                const data = {
                    type,
                    authors,
                    editors,
                    issued: queryTextContent(child, "year"),
                    "container-title": queryTextContent(child, "source"),
                    title: getTitle(),
                    volume: queryTextContent(child, "volume"),
                    issue: queryTextContent(child, "issue"),
                    URL: queryAttribute(child, "ext-link", "xlink:href") || queryTextContent(child, "uri"),
                    city: queryTextContent(child, "publisher-loc"),
                    page: getPages(),
                    version: queryTextContent(child, "version") || queryTextContent(child, 'comment[content-type="Version"]'),
                    edition: queryTextContent(child, "edition"),
                    publisher: queryTextContent(child, "publisher-name"),
                    "translated-title": queryTextContent(child, "trans-title"),
                    "event-title": queryTextContent(child, "conf-name"),
                    "event-location": queryTextContent(child, "conf-loc"),
                    "event-date": queryTextContent(child, "conf-date"),
                    ISBN: queryTextContent(child, "isbn"),
                    DOI: getDOI()
                };
                // serviceShare.RefsApiService.getReferenceTypes().subscribe((refTypes: any) => {
                // TODO: ADD TYPES
                // })
                this.initializeReferenceWithStyleAndCitation(type, id, data);
            }
        }
    }

    figureComponentsInPrevew = [];
    bottomOffset = 0.30;
    columnsFormControl = {value: 2};
    rowTemplate: any;
    figureRows: any;
    maxImgHeightPers: any;
    maxImgWidthPers: any;
    figureCanvasData = {};
    figNewComponents: any;
    urlMapping = {};
    
    getMappedComponentsForPreviw = (selfRef: any) => () => {
        return JSON.parse(JSON.stringify(
          selfRef.figNewComponents.map((x)=>{return {container:x}})
        ))
    }

    figures: any = {};
    figuresNumbers: any = [];
    figuresTemplates: any = {};

    parseFigures(figGroups: Element[]) {
        this.figures = this.serviceShare.YdocService.figuresMap.get("ArticleFigures") || {};
        this.figuresNumbers = this.serviceShare.YdocService.figuresMap.get("ArticleFiguresNumbers") || [];
        this.figuresTemplates = this.serviceShare.YdocService.figuresMap.get("figuresTemplates") || {};
        const hasFigures = !!Object.keys(this.figures).length;
        figGroups.forEach((f, i) => {
            const caption = f.querySelector("caption");
            const id = f.id;
            const figure = {
                jats_id: id,
                description: caption || "",
                figureID: uuidv4(),
                figureNumber: hasFigures ? Math.max(...Object.values(this.figures).map((v: any) => v.figureNumber)) + 1 : i,
                figurePlace: "endEditor",
                viewed_by_citat: "endEditor",
                clientID: this.serviceShare.YdocService.ydoc.clientID,
                isNew: true
            };
            const figs = Array.from(f.children).filter(ch => ch.nodeName == "fig");
            const components = [];

            if(f.nodeName == "fig-group") {
                figs.forEach((fig, i) => {
                    //@ts-ignore
                    const url = fig.querySelector("uri")?.textContent || fig.querySelector("graphic")?.attributes?.getNamedItem?.("xlink:href")?.value || "";
                    const description = fig.querySelector("caption");
                    if(url){
                        components.push({
                            description,
                            url,
                            componentType: fig.querySelector("graphic") ? "image" : "video"
                        })
                    }
                })
            } else {
                //@ts-ignore
                const url = f.querySelector("uri")?.textContent || f.querySelector("graphic")?.getNamedItem?.("xlink:href")?.value || "";
                if(url){
                    components.push({
                        description: "",
                        url,
                        componentType: f.querySelector("graphic") ? "image" : "video"
                    })
                }
            }
            this.figNewComponents = components;
            figure["components"] = components;
            this.serviceShare.updatePreview(this)(false);
            figure["canvasData"] = this.figureCanvasData;
            this.figures[figure.figureID] = figure;
            this.figuresNumbers.push(figure.figureID);
            this.figuresTemplates[figure.figureID] = { html: figuresHtmlTemplate };
        })
    }

    addContentToFigures() {
        Object.keys(this.figures).forEach((key => {
            if(this.figures[key].isNew) {
                const caption = this.figures[key].description as HTMLTableCaptionElement;
                if(caption) {
                    const description = caption?.childNodes ? Array.from(caption.children).map(ch => this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)) : "";
                    const content = description ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer) : "";
                    this.figures[key].description = content;
                }
    
                this.figures[key].components.forEach((comp: any, i: number) => {
                    const caption = comp.description as HTMLTableCaptionElement;
    
                    if(caption) {
                        const description = caption?.childNodes ? Array.from(caption.children).map(ch => this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)) : "";
                        const content = description ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer) : "";
                        comp.description = content;
                    }
                })
                delete this.figures[key].isNew;
            }
        }))
    }

    tables: any = {};
    tablesNumbers: any = [];
    tablesTemplates: any = {};

    parseTables(tableEls: Element[]) {
        this.tables = this.serviceShare.YdocService.tablesMap.get("ArticleTables") || {};
        this.tablesNumbers = this.serviceShare.YdocService.tablesMap.get("ArticleTablesNumbers") || [];
        this.tablesTemplates = this.serviceShare.YdocService.tablesMap.get("tablesTemplates") || {};
        const template = this.serviceShare.YdocService.tablesMap?.get("tablesInitialTemplate");
        const hasTables = !!Object.keys(this.tables).length;
        tableEls.forEach((t, i) => {
            /**
             * header: submision.data.tableHeader,
                editMode: selfRef.editMode,
                tableNumber: selfRef.data.index,
                clientID: this.ydocService.ydoc.clientID,
                "tableID": submision.data.tableID,
                "tablePlace": selfRef.data.tableID ? selfRef.data.table?.tablePlace! : "endEditor",
                "viewed_by_citat": selfRef.data.tableID ? selfRef.data.table?.viewed_by_citat! : "endEditor",
             */
            const jats_id = t.id;
            const header = t.children?.[1] || ""
            const tableContent = `<table>${t.children?.[2]?.innerHTML || t.querySelector("table")?.innerHTML}</table>` || "<table><tbody><tr><td><form-field><p></p></form-field></td><td><form-field><p></p></form-field></td><td><form-field><p></p></form-field></td></tr></table></tbody>";
            const tableFooter = t?.children?.[3] || "<p></p>";
            const table = {
                jats_id,
                tableID: uuidv4(),
                tableNumber: hasTables ? Math.max(...Object.values(this.tables).map((v: any) => v.tableNumber)) + 1 : i,
                tablePlace: "endEditor",
                viewed_by_citat: "endEditor",
                clientID: this.serviceShare.YdocService.ydoc.clientID,
                header,
                tableContent,
                tableFooter,
                isNew: true
            };
            
            this.tables[table.tableID] = table;
            this.tablesNumbers.push(table.tableID);
            this.tablesTemplates[table.tableID] = { html: template };
        })
    }

    addContentToTables() {
        this.serviceShare.ProsemirrorEditorsService.editMode = true;
        Object.keys(this.tables).forEach((async (key) => {
            if(this.tables[key].isNew) {
                const header = this.tables[key].header as HTMLTableCaptionElement;
                const footer = this.tables[key].tableFooter as HTMLTableCaptionElement;
                if(header) {
                    const description = header?.childNodes ? Array.from(header.children).map(ch => this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)) : "";
                    const content = description ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer) : "";
                    this.tables[key].header = content;
                }
                if(footer) {
                    const description = footer?.childNodes ? Array.from(footer.children).map(ch => this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)) : "";
                    const content = description ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer) : "";
                    this.tables[key].tableFooter = content;
                }
                const tableData = {
                    ...this.tables[key],
                    viewed_by_citat: "endEditor",
                    tableContent: this.tables[key].tableContent, 
                    tableHeader: this.tables[key].header, 
                    tableFooter: this.tables[key].tableFooter
                }
                
                const tableFormGroup = citationElementMap.table_citation.buildElementFormGroup(tableData);
                const interpolatedHTML = await this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(this.tablesTemplates[key].html, tableData, tableFormGroup, null, {table: true});
                const templ = document.createElement('div');
                templ.innerHTML = interpolatedHTML;
                const Slice = PModel.DOMParser.fromSchema(schema).parse(templ.firstChild);
                const node = schema.nodes["tables_nodes_container"].create({}, Slice.content.firstChild);
                
                const xmlFragment = this.serviceShare.ProsemirrorEditorsService.getXmlFragment(undefined, key);
                updateYFragment(this.serviceShare.ProsemirrorEditorsService.ydoc, xmlFragment, node, new Map());
                this.serviceShare.ProsemirrorEditorsService.renderCustomEditor(document.createElement("div"), key, true);
                delete this.tables[key].tableFooter;
                delete this.tables[key].tableContent;
                delete this.tables[key].isNew;
            }
        }))
        setTimeout(() => {
            this.serviceShare.ProsemirrorEditorsService.editMode = false;
        }, 200);
    }

    supplementaryFilesNumbers: any = [];
    supplementaryFiles: any = {};
    supplFileTemplates: any = {};

    parseSupplementaryMaterials (materialEls: Element[]) {
        this.supplementaryFiles = this.serviceShare.YdocService.supplementaryFilesMap.get("supplementaryFiles") || {};
        this.supplementaryFilesNumbers = this.serviceShare.YdocService.supplementaryFilesMap.get("supplementaryFilesNumbers") || [];
        this.supplFileTemplates = this.serviceShare.YdocService.supplementaryFilesMap.get("supplementaryFilesTemplates") || {};
        const template = this.serviceShare.YdocService.supplementaryFilesMap?.get("supplementaryFilesInitialTemplate");
        const hasSuplFiles = !!Object.keys(this.supplementaryFiles).length;
        materialEls.forEach((supFile, i) => {
            console.log(supFile);            
            const jats_id = supFile.id;
            const caption = supFile.querySelector("caption");
            
            const suppFile = {
                jats_id,
                supplementary_file_ID: uuidv4(),
                supplementary_file_number: hasSuplFiles ? Math.max(...Object.values(this.supplementaryFiles).map((v: any) => v.supplementary_file_number)) + 1 : i,
                title: caption.children[0] || "",
                brief_description: supFile.querySelector("p") || caption.children[1] || "",
                authors: supFile.querySelector("attrib") || "",
                url: supFile.querySelector("ext-link")?.attributes?.getNamedItem("xlink:href")?.value || supFile.querySelector("media")?.attributes?.getNamedItem("xlink:href")?.value || "",
                data_type: supFile.attributes.getNamedItem("mimetype")?.value || supFile.querySelector("statement")?.children?.[1]?.textContent || "",
                isNew: true
            }
            this.supplementaryFilesNumbers.push(suppFile.supplementary_file_ID);
            this.supplementaryFiles[suppFile.supplementary_file_ID] = suppFile;
            this.supplFileTemplates[suppFile.supplementary_file_ID] = { html: template};
        })
    }

    addContentToSupplementaryMaterials() {
        Object.keys(this.supplementaryFiles).forEach((key => {
            if(this.supplementaryFiles[key].isNew) {
                const title = this.supplementaryFiles[key].title as HTMLElement;
                const brief_description = this.supplementaryFiles[key].brief_description as HTMLElement;
                const authors = this.supplementaryFiles[key].authors as HTMLElement;
                const data_type = this.supplementaryFiles[key].data_type as HTMLElement;
                if(title) {
                    const description = title?.childNodes ? schema.nodes.paragraph.create({}, ...Array.from(title.childNodes).map(ch => this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch))) : "";
                    const content = description ? getHtmlFromFragment(PModel.Fragment.from(description.content), this.DOMPMSerializer) : "";
                    this.supplementaryFiles[key].title = content;
                }
                if(brief_description) {
                    const description = brief_description?.childNodes ? schema.nodes.paragraph.create({}, ...Array.from(brief_description.childNodes).map(ch => this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch))) : "";
                    const content = description ? getHtmlFromFragment(PModel.Fragment.from(description.content), this.DOMPMSerializer) : "";
                    this.supplementaryFiles[key].brief_description = content;
                }
                if(authors) {
                    const description = authors?.childNodes ? Array.from(authors.childNodes).map(ch => this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)) : "";
                    const content = description ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer) : "";
                    this.supplementaryFiles[key].authors = content;
                }
                if(typeof data_type != "string") {
                    const description = data_type?.childNodes ? Array.from(data_type.childNodes).map(ch => this.createProsemirrorNodes(ch.nodeName, ch.textContent, ch)) : "";
                    const content = description ? getHtmlFromFragment(PModel.Fragment.from(...description), this.DOMPMSerializer) : "";
                    this.supplementaryFiles[key].data_type = content;
                }
                delete this.supplementaryFiles[key].isNew;
            }
        }))
    }

    parseSectionsFromModal(sections: Element[], articleSection: articleSection, level: number, shouldPreserveSpaces: boolean, title: string, hasOriginalContent: boolean): Promise<PModel.Node> {
        return new Promise((resolve, reject) => {
            this.serviceShare.ProsemirrorEditorsService.editMode = true;

            if(articleSection.title.name == "Taxon") {
                this.taxonData = {};
                const el = sections.find(el => el?.nodeName == "tp:nomenclature");
                if(!el) resolve(schema.nodes.doc.create({}));

                this.getTaxonData(Array.from(sections[0].children));
                const htmlTemplate = articleSection.prosemirrorHTMLNodesTempl;
                const sectionForm = new UntypedFormGroup({});
    
                filterFieldsValues(articleSection.formIOSchema,{ data: this.taxonData }, this.serviceShare, articleSection.sectionID, true, '', false);
                this.serviceShare.FormBuilderService.populateDefaultValues(this.taxonData, articleSection.formIOSchema, articleSection.sectionID, articleSection, sectionForm);
                this.serviceShare.FormBuilderService.buildFormGroupFromSchema(sectionForm, articleSection.formIOSchema, articleSection);
                sectionForm.patchValue(this.taxonData);
    
                this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(htmlTemplate, this.taxonData, sectionForm, null).then((result: string) => {
                    const templDiv = document.createElement('div');
                    templDiv.innerHTML = result;
                    const node = PModel.DOMParser.fromSchema(schema).parse(templDiv.firstChild);
                    resolve(node);
                });
            } else if (articleSection.title.name == "[MM] Materials") {
                const element = sections.find(el => el?.nodeName == "tp:treatment-sec" && el?.attributes?.getNamedItem?.("sec-type")?.value == "materials");
                
                if(!element) resolve(schema.nodes.doc.create({}));

                const materialSections = this.getMaterialsData(element);
                const { data, nodeForm } = this.serviceShare.TreeService.orderMaterialSections(materialSections, new UntypedFormGroup({}));
                
                this.serviceShare.ProsemirrorEditorsService.interpolateTemplate(articleSection.prosemirrorHTMLNodesTempl, data, nodeForm, null).then((result: string) => {
                    const templDiv = document.createElement('div');
                    templDiv.innerHTML = result;

                    const node = PModel.DOMParser.fromSchema(schema).parse(templDiv.firstChild);
                    resolve(node);
                  })

            } else if (this.dataPaperService.dataPaperSpecificSections.includes(articleSection.title.name)) {
                // Custom parsing for data paper sections
                this.dataPaperService.parseDataPaperSpecificSectionsFromJats(sections, articleSection, resolve);
            } else {
                const nodes = [];
                const sectionContent = [];
                const wordsContent = [];
                const titleFormioKey = articleSection.title.name == "[AM] Title" ? articleSection.formIOSchema.components?.[0]?.key : undefined;
        
                const content = schema.nodes.paragraph.create({ 
                    contenteditableNode: titleFormioKey ? true : articleSection.title.editable, 
                    formControlName: titleFormioKey || "sectionTreeTitle", 
                    controlPath: titleFormioKey || "sectionTreeTitle" 
                }, titleFormioKey && hasOriginalContent ? 
                    schema.text(`${title} ${this.serviceShare.ProsemirrorEditorsService.editorContainers[articleSection.sectionID].editorView.state.doc.textContent}`) :  schema.text(title)
                );

                const heading = schema.nodes.heading.create({ 
                    tagName: "h" + level, 
                    formControlName:titleFormioKey || "sectionTreeTitle", 
                    controlPath: titleFormioKey || "sectionTreeTitle", 
                    contenteditableNode: titleFormioKey ? true : articleSection.title.editable
                    }, content);
                nodes.push(heading);
                sections.forEach(sec => {
                    if(sec) {
                        Array.from(sec.children).forEach((child, i, arr) => {
                           if(child.nodeName != "sec" && child.nodeName != "table-wrap" && child.nodeName != "title" && child.nodeName != "label") {
                                if(child.nodeName == "kwd") {
                                    const separator = arr.length - 1 == i ? "" : ", ";
                                    wordsContent.push(...this.createProsemirrorNodes(child.nodeName, (shouldPreserveSpaces ? child.textContent : child.textContent.split("\n").join(" ")).trim() + separator, child));
                                } else {
                                    sectionContent.push(...this.parseSectionContent(child));
                                }
                            }
                        })
                    }
                })
        
                if(!titleFormioKey) {
                    const paragraph = schema.nodes.paragraph.create({
                        formControlName: "sectionContent", 
                        controlPath: "sectionContent" 
                    }, wordsContent);
                    let content: PModel.Node[] = [];
                    if(sectionContent.length > 0 && wordsContent.length > 0) {
                        content = [...sectionContent, paragraph];
                    } else if (sectionContent.length > 0 && wordsContent.length == 0) {
                        content = sectionContent;
                    }

                    if(hasOriginalContent) {
                        const originalContent = this.serviceShare.ProsemirrorEditorsService.editorContainers[articleSection.sectionID].editorView.state.doc.lastChild;
                        //@ts-ignore
                        if(originalContent && originalContent.content?.content[0] && originalContent.content.content[0].childCount > 0 || originalContent && originalContent.content.content?.length > 1) {
                            //@ts-ignore
                            content.push(...originalContent.content.content);
                        }
                    }

                    if(!content.length) {
                        content = [paragraph];
                    }

                    nodes.push(schema.nodes.form_field.create({
                        formControlName: "sectionContent", 
                        controlPath: "sectionContent" 
                    }, content));
                }
                resolve(schema.nodes.doc.create({}, nodes));
            }

            setTimeout(() => {
                this.serviceShare.ProsemirrorEditorsService.editMode = false;
            }, 200);
        })
    }

    parseCollectionData(sec: articleSection, element: Element) {
        const iterateCollectionData = (elements: Element[], level: number) => {
            elements.forEach(child => {
                if (child.nodeName != "sec") return;

                const secType = child.attributes.getNamedItem("sec-type").value.toLocaleLowerCase();
                // We use the text content of the title element to differentiate the sections which are instances of [PS] Subsection as they have the same sec-type
                const secTitle = child.children[0].textContent.toLocaleLowerCase();

                if (secTitle.includes("collection name")) {
                    // We use the label of the section to differentiate the sections which are instances of [PS] Subsection as they have the same title
                    const section = sec.children.find(s => s.title.label == "Collection name"); 
                    this.iterateSections(section, child, level);
                } else if (secTitle.includes("collection identifier") && !secTitle.includes("parent")) {
                    const section = sec.children.find(s => s.title.label == "Collection identifier");
                    this.iterateSections(section, child, level);
                } else if (secTitle.includes("parent collection identifier")) {
                    const section = sec.children.find(s => s.title.label == "Parent collection identifier");
                    this.iterateSections(section, child, level);
                } else if (secType.includes("specimen preservation method")) {
                    const section = sec.children.find(s => s.title.name == "[PS] Specimen preservation method");
                    this.iterateSections(section, child, level);
                } else if (secType.includes("curatorial unit")) {
                    const section = sec.children.find(s => s.title.name == "[PS] Curatorial unit");
                    this.iterateSections(section, child, level);
                }
            });
        }

        this.allJatsSections.push({ title: sec.title.label, section: element });
        this.sectionsContent[sec.sectionID] = {
            doc: null,
            sectionTitle: sec.title.label,
            parsedSecTitle: sec.title.label,
            level: this.sectionsContent[sec.sectionID]?.level || this.serviceShare.TreeService.getNodeLevel(sec).hTag,
            secID: sec.sectionID
        };

        // Iterate over all `sec` children elements
        const collectionSectionsFromXML = Array.from(element.children).filter(ch => ch.nodeName == "sec");
        iterateCollectionData(collectionSectionsFromXML, 3);
    }

    parseDataResources(sec: articleSection, element: Element) {

        const renderDataSetChildren = (dSec: articleSection, element: Element, sectionName: string) => {
            if (!dSec) return;

            const child = dSec.children.find(sec => sec.title.name == sectionName);
            if(child) {
                this.iterateSections(child, element, 4);
            }
        }

        const iterateDataSets = (element: Element, section: articleSection, level: number) => {
            Array.from(element.children).forEach(child => {
                if (child.nodeName != "sec") return;
                const secType = child.attributes.getNamedItem("sec-type").value.toLocaleLowerCase();

                if (secType.includes("data set name")) {
                    renderDataSetChildren(section, child, "[PS] Data set name");
                } else if (secType.includes("data format") && !secType.includes("version")) {
                    renderDataSetChildren(section, child, "[PS] Data format");
                } else if (secType.includes("character set")) {
                    renderDataSetChildren(section, child, "[PS] Character set");
                } else if (secType.includes("download url")) {
                    renderDataSetChildren(section, child, "[PS] Download URL");
                } else if (secType.includes("data format version")) {
                    renderDataSetChildren(section, child, "[PS] Data format version");
                } else if (secType.includes("description")) {
                    const descriptionSec = section.children.find(sec => sec.title.name == "[PS] Data set description");
                    this.iterateSections(descriptionSec, child, 4);
                }
            });
        }

        const renderDataSet = (element: Element, section: articleSection, level: number) => {
            if (element && !section) { // XML section is not present in the editor's sections structure.
                let fileredSections = getFilteredSectionChooseData(this.serviceShare.TreeService.findNodeById(sec.sectionID), this.serviceShare.TreeService);
                const section = fileredSections.find(sec => sec.source == "template");

                if (section) { // Add the section in the editor
                    const newSection = this.serviceShare.TreeService.addNodeAtPlaceChange(sec.sectionID, section.template, "end");
                    setTimeout(() => {
                        renderDataSet(element, newSection, 3);
                    }, 200);
                }
            } else { // XML section is present in the editor's sections structure.
                this.allJatsSections.push({ title: section.title.label, section: element, secID: section.sectionID });
                this.sectionsContent[section.sectionID] = {
                    doc: null,
                    sectionTitle: section.title.label,
                    parsedSecTitle: section.title.label,
                    level:  this.sectionsContent[section.sectionID]?.level || this.serviceShare.TreeService.getNodeLevel(section).hTag,
                    secID: section.sectionID
                };

                iterateDataSets(element, section, level);
            }
        }

        const iterateDataResources = (elements: Element[], level: number) => {
            const dataSetSections = sec.children.filter(s => s.title.name == "[PS] Data set");
            const dataSetElements: Element[] = [];

            elements.forEach(child => {
                if (child.nodeName != "sec") return;

                const secType = child.attributes.getNamedItem("sec-type").value.toLocaleLowerCase();
                // Checks if sec-type is "Data set" and not "Data set name" or "Number of data sets"
                const isSecTypeDataSet = /data set/.test(secType) && !/(name|number)/.test(secType);

                if (isSecTypeDataSet) {
                    dataSetElements.push(child);
                } else if (secType.includes("data package title")) {
                    const section = sec.children.find(s => s.title.name == "[PS] Data package title");
                    this.iterateSections(section, child, level);
                } else if (secType.includes("resource link")) {
                    const section = sec.children.find(s => s.title.name == "[PS] Resource link");
                    this.iterateSections(section, child, level);
                } else if (secType.includes("alternative identifiers")) {
                    const section = sec.children.find(s => s.title.name == "[PS] Alternative identifiers");
                    this.iterateSections(section, child, level);
                }
            });

            // Iterate over each found data set section in the XML and add it in the editor if missing
            dataSetElements.forEach((dataSetEl, index) => {
                renderDataSet(dataSetEl, dataSetSections[index], level);
            })
        }

        this.allJatsSections.push({ title: sec.title.label, section: element });
        this.sectionsContent[sec.sectionID] = {
            doc: null,
            sectionTitle: sec.title.label,
            parsedSecTitle: sec.title.label,
            level: this.sectionsContent[sec.sectionID]?.level || this.serviceShare.TreeService.getNodeLevel(sec).hTag,
            secID: sec.sectionID
        };

        // Iterate over all `sec` children elements
        const dataResourceSectionsFromXML = Array.from(element.children).filter(ch => ch.nodeName == "sec");
        iterateDataResources(dataResourceSectionsFromXML, 3);
    }

    taxonData: any = {};
    materialsData: any[] = [];

    parseTaxonTreatments(sec: articleSection, element: Element) {
        let taxonSec: articleSection;

        const renderTreatmentSections = (tSec: articleSection, ch: Element, sectionName: string) => {
            const section = tSec.children.find(s => s.title.name == "[MM] Treatment sections");
            if(section) {
                const s = section.children.find(sec => sec.title.name == sectionName);
                if(s) {
                    this.iterateSections(s, ch, 3);
                    // this.serviceShare.TreeService.showHideSection(s.sectionID, "block");
                }
            } else {
                const section = tSec.children.find(s => s.title.name == sectionName);
                this.iterateSections(section, ch, this.serviceShare.TreeService.getNodeLevel(section).hTag);
            }
        }

        const iterateTaxon = (elements: Element[], data?: any, isTaxon?: boolean) => {
            (isTaxon ? elements.slice(1) : elements).forEach((child, index) => {
                if(child.nodeName == "tp:taxon-treatment") {
                    taxonSec = sec.children[index];
                    if(taxonSec) {
                        iterateTaxon(Array.from(child.children));
                    }
                } else if (child.nodeName == "tp:nomenclature") {
                    this.getTaxonData(Array.from(child.children));
                    const valuesCopy = {};
                    Object.keys(this.taxonData).forEach((key)=>{
                        valuesCopy[key] = this.taxonData[key];
                    })
                    const label = this.serviceShare.TreeService.generateTaxonTitle(this.taxonData).taxonTitle;

                    this.allJatsSections.push({ title: label, section: child });
                    this.sectionsContent[taxonSec.sectionID] = {
                        doc: null,
                        sectionTitle: label || taxonSec.title.name,
                        parsedSecTitle: label,
                        level:  this.sectionsContent[sec.sectionID]?.level || this.serviceShare.TreeService.getNodeLevel(sec).hTag,
                        secID: taxonSec.sectionID,
                        disabled: true
                    };
                } else if (child.nodeName == "tp:treatment-sec" && child.attributes.getNamedItem("sec-type").value == "materials") {
                    const materialsParent = taxonSec.children.find(sec => sec.title.name == "[MM] Materials");
                    this.allJatsSections.push({ title: "Materials Download as CSV or XLSX", section: child });
                    this.sectionsContent[materialsParent.sectionID] = {
                        doc: null,
                        sectionTitle: materialsParent.title.label,
                        parsedSecTitle: "Materials Download as CSV or XLSX",
                        level:  this.sectionsContent[sec.sectionID]?.level || this.serviceShare.TreeService.getNodeLevel(materialsParent).hTag,
                        secID: materialsParent.sectionID
                    };
                } else if (child.nodeName == "tp:treatment-sec" && child.attributes.getNamedItem("sec-type")?.value == "Description") {
                    renderTreatmentSections(taxonSec, child, "[MM] Description");
                } else if (child.nodeName == "tp:treatment-sec" && child.attributes.getNamedItem("sec-type")?.value == "Diagnosis") {
                    renderTreatmentSections(taxonSec, child, "[MM] Diagnosis");
                } else if (child.nodeName == "tp:treatment-sec" && child.attributes.getNamedItem("sec-type")?.value == "Ecology") {
                    renderTreatmentSections(taxonSec, child, "[MM] Etymology");
                } else if (child.nodeName == "tp:treatment-sec" && child.attributes.getNamedItem("sec-type")?.value == "Conservation") {
                    renderTreatmentSections(taxonSec, child, "[MM] Distribution");
                } else if (child.nodeName == "tp:treatment-sec" && child.attributes.getNamedItem("sec-type")?.value == "Biology") {
                    renderTreatmentSections(taxonSec, child, "[MM] Biology");
                } else if (child.nodeName == "tp:treatment-sec" && child.attributes.getNamedItem("sec-type")?.value == "Notes") {
                    renderTreatmentSections(taxonSec, child, "[MM] Notes");
                } else if (child.nodeName == "tp:treatment-sec" && child.attributes.getNamedItem("sec-type")?.value == "Taxon discussion") {
                    renderTreatmentSections(taxonSec, child, "[MM] Taxon discussion");
                }
            })
        }

        iterateTaxon(Array.from(element.children), undefined, true);
    }

    getMaterialsData(element: Element) {
        this.materialsData = [];
        const listItems = Array.from(element.querySelectorAll("list-item"));
        // typeStatus
        // typeHeading
        listItems.forEach(item => {
            const materialData = {};
            Array.from(item.children[0]?.children || []).forEach(el => {
                if (el.nodeName == "named-content") {
                    const [_, prop] = el.attributes.getNamedItem("content-type").value.split(":");
                    materialData[prop] = el.textContent;
                }
            })
            materialData["typeHeading"] = materialData["typeStatus"];
            this.materialsData.push(materialData);
        });
        const sections = [];
        for (const row of this.materialsData) {
            Object.keys(row).forEach((key: string) => {
                if(key !== "typeStatus") {
                    row[key] = row[key].trim() + ";&nbsp;";
                }
            });
            const section = JSON.parse(JSON.stringify(material));
            section.mode = "";
            section.active = false;
            section.defaultFormIOValues = row;
            section.sectionID = uuidv4();
            section.label = row.typeStatus;
            section.parentId = row.parentId;
            sections.push(section);
        }

        return sections;
    }

    getTaxonData(elements: Element[]) {
        elements.forEach(el => {
            if (el.nodeName == "tp:taxon-name") {
                this.getTaxonData(Array.from(el.children));
            } else if (el.nodeName == "tp:taxon-name-part") {
                const attribute = el.attributes.getNamedItem("taxon-name-part-type")?.value;
                this.taxonData[attribute] = el.textContent || "";
                if(attribute == "genus" || attribute == "species") {
                    this.taxonData["rank"] = "subspecies";
                }
            } else if (el.nodeName == "tp:taxon-authority") {
                this.taxonData["authorandyear"] = el.textContent || "";
            } else if (el.nodeName == "tp:taxon-status") {
                this.taxonData["typeoftreatment"] = el.textContent || "";
            }
        })
    }

    addCitableElementsToEditor() {
        this.subscription.unsubscribe();

        this.serviceShare.YdocService.referenceCitationsMap.set("refsAddedToArticle", {});
        this.serviceShare.YdocService.referenceCitationsMap.set("referencesInEditor", {});

        if(this.figuresNumbers.length > 0) {
            this.serviceShare.YdocService.figuresMap?.set('figuresTemplates', this.figuresTemplates);
            setTimeout(() => {
                this.serviceShare.CitableElementsService.writeElementDataGlobal(this.figures, this.figuresNumbers, 'citation');
            }, 100);
        }
        if(Object.keys(this.refsObj).length > 0) {
            Object.keys(this.referenceCitations).forEach((key) => {
                this.referenceCitations[key].citationLayout = this.layoutOptions.find(opt => opt.name == this.selectedLayoutOption) || this.layoutOptions[0]
            })
            this.serviceShare.YdocService.referenceCitationsMap.set("refsAddedToArticle", this.refsObj);
            this.serviceShare.YdocService.referenceCitationsMap.set('referenceCitations', this.referenceCitations);
            setTimeout(() => {
                this.serviceShare.EditorsRefsManagerService.updateRefsInEndEditorAndTheirCitations();
            }, 100);
        }
        if(this.endNotesNumbers.length > 0) {
            this.serviceShare.CitableElementsService.writeElementDataGlobal(this.endNotes, this.endNotesNumbers, 'end_note_citation');
        }
        if(this.tablesNumbers.length > 0) {
            this.serviceShare.YdocService.tablesMap?.set('tablesTemplates', this.tablesTemplates);
            setTimeout(() => {
                this.serviceShare.CitableElementsService.writeElementDataGlobal(this.tables, this.tablesNumbers, 'table_citation');
            }, 200);
        }
        if(this.supplementaryFilesNumbers.length > 0) {
            this.serviceShare.YdocService.supplementaryFilesMap?.set('supplementaryFilesTemplates', this.supplFileTemplates);
            setTimeout(() => {
                this.serviceShare.CitableElementsService.writeElementDataGlobal(this.supplementaryFiles, this.supplementaryFilesNumbers, 'supplementary_file_citation');
            }, 100);
        }
    }

    setEmptyCitableElements() {
        // this.figures = {};
        // this.figuresNumbers = [];
        // this.figuresTemplates = {};
        // this.tables = {};
        // this.tablesNumbers = [];
        // this.tablesTemplates = {};
        // this.supplementaryFilesNumbers = [];
        // this.supplementaryFiles = {};
        // this.supplFileTemplates = {};
        // this.endNotesNumbers = [];
        // this.endNotes = {};
        // this.refsObj = {};
    }
}
