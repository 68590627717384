import { E, N } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { clearRefFromFormControl } from '@app/editor/dialogs/refs-in-article-dialog/refs-in-article-dialog.component';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { PMDomParser } from '@app/editor/utils/Schema';
import { uuidv4 } from 'lib0/random';
import { endsWith, keys } from 'lodash';
import { DOMParser, DOMSerializer, Fragment, Mark, Node, Schema } from 'prosemirror-model';
import { EditorState, TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export let CiToTypes = [
  { label: "None", link: "" },
  { label: "agrees with", link: "http://purl.org/spar/cito/agreesWith" },
  { label: "cites as authority", link: "http://purl.org/spar/cito/citesAsAuthority" },
  { label: "cites as recommended reading", link: "http://purl.org/spar/cito/citesAsRecommendedReading" },
]

export function getHtmlInlineNodes(htmlSteing: string) {
  let container = document.createElement('p');
  let html = htmlSteing.replace(/<p[^>]+>|<div[^>]+>|<\/p>|<\/div>/gm, '');

  let matched = html.match(/(https:\/\/|http:\/\/).+(\.*)/g);

  if(matched) {
    html = html.replace(matched[0], '')
    let a = document.createElement('a');
    a.append(document.createTextNode(matched[0]))
    a.href = matched[0];
    a.title = html.trim();
    container.innerHTML = html;
    container.append(a);
  } else {
    container.innerHTML = html;
  }

  let nodes = PMDomParser.parseSlice(container);
  //@ts-ignore
  return nodes.content.content
}

@Injectable({
  providedIn: 'root'
})

export class EditorsRefsManagerService {

  constructor(private serviceShare: ServiceShare) {
    this.serviceShare.shareSelf('EditorsRefsManagerService', this);

    this.serviceShare.YdocService.ydocStateObservable.subscribe(({ event }) => {
      if(event == "docIsBuild") {        
        const referenceModalTemplate = this.serviceShare.YdocService.citableElementsSections.find((sec: any) => sec.name == "References")?.template;

        if(referenceModalTemplate) {
          this.closeOnClickOutside = true;
        }
      }
    })
  }

  editRef = false;
  closeOnClickOutside = false;
  dothSaveToHistory = false;

  citateSelectedReferencesInEditor(
    citation: { text: string; refCitationIDs: string[]; citationLayout: number; sortOptions: any[]; },
    view: EditorView,
    citedRefsAtPos?: {
    citedRefsIds: string[],
    citationNodeSize: number,
    citationPos: number,
    refCitationID: string,
    otherMarks?: Mark[]
    }) {
    const refsInYdoc = this.serviceShare.YdocService.referenceCitationsMap.get('refsAddedToArticle');
    const citedRefsInArticle = this.serviceShare.YdocService.referenceCitationsMap.get('citedRefsInArticle');
    const referenceCitations = this.serviceShare.YdocService.referenceCitationsMap.get('referenceCitations');

    const refCitationID = citedRefsAtPos?.refCitationID || uuidv4();
    referenceCitations[refCitationID] = {
      text: citation.text,
      refCitationIDs: citation.refCitationIDs,
      citationLayout: citation.citationLayout,
      sortOptions: citation.sortOptions
    }

    let state = view.state;
    let schema = state.schema as Schema;

    const tooltip = citation.refCitationIDs.map(id => `${refsInYdoc[id].bibliography?.trim()}\nCiTO: ${refsInYdoc[id].refCiTO?.label || CiToTypes[0].label}`).join("\n");

    let nodeAttrs = {
      refCitationID: refCitationID,
      citedRefsIds: citation.refCitationIDs,
      contenteditableNode: false,
      citedRefsCiTOs: citation.refCitationIDs.map((id: string) => refsInYdoc[id].refCiTO?.label || CiToTypes[0].label),
      tooltip
    }
    const mark = schema.marks.reference_citation.create(nodeAttrs);
    let citationTxt = schema.text(citation.text, [mark, ...(citedRefsAtPos?.otherMarks || [])]);

    this.serviceShare.YjsHistoryService.captureBigOperation();

    if (!citedRefsAtPos) {
      view.dispatch(state.tr.replaceSelectionWith(citationTxt, false));
    } else {
      view.dispatch(state.tr.replaceWith(citedRefsAtPos.citationPos, citedRefsAtPos.citationPos + citedRefsAtPos.citationNodeSize, citationTxt));
    }

    nodeAttrs.citedRefsIds.forEach((refId) => {
      if(!citedRefsInArticle[refId]) {
        citedRefsInArticle[refId] = 1;
      } else {
        citedRefsInArticle[refId]++;
      }
    })
    
    if(this.serviceShare.compareObjects(this.serviceShare.YdocService.referenceCitationsMap.get("citedRefsInArticle"), citedRefsInArticle)) {
      this.serviceShare.YdocService.referenceCitationsMap.set('citedRefsInArticle', citedRefsInArticle);
    }
    if(this.serviceShare.compareObjects(this.serviceShare.YdocService.referenceCitationsMap.get("referenceCitations"), referenceCitations)) {
      this.serviceShare.YdocService.referenceCitationsMap.set('referenceCitations', referenceCitations);
    }
    this.serviceShare.YjsHistoryService.endBigOperationCapture();
  }

  updateRefsInEndEditorAndTheirCitations(olderVersionReferences?: any) {
    this.checkIfAnyRefsShouldBeAddedToEndEditor(olderVersionReferences);
    if(olderVersionReferences) return;
    this.checkIfShouldUpdateRefs();
    setTimeout(() => {
      this.serviceShare.ProsemirrorEditorsService.editMode = false;
    }, 200);
  }

  checkIfShouldUpdateRefs() {
    let refsInEndEditor = this.serviceShare.YdocService!.referenceCitationsMap?.get('referencesInEditor');
    let refsInArticle = this.serviceShare.YdocService.referenceCitationsMap.get('refsAddedToArticle');

    let refsThatViewsShouldBeUpdated = {};
    let deletedRefs = {}
    Object.keys(refsInEndEditor).forEach((refId) => {
      if (!refsInArticle[refId]) {
        deletedRefs[refId] = refsInEndEditor[refId]
      } else if (
        refsInEndEditor[refId].citation.textContent != refsInArticle[refId].citation.textContent ||
        refsInEndEditor[refId].refStyle.name != refsInArticle[refId].refStyle.name ||
        refsInEndEditor[refId].refStyle.last_modified != refsInArticle[refId].refStyle.last_modified ||
        refsInEndEditor[refId].refStyle.label != refsInArticle[refId].refStyle.label ||
        refsInEndEditor[refId].refType.last_modified != refsInArticle[refId].refType.last_modified ||
        refsInEndEditor[refId].refType.refTypeId != refsInArticle[refId].refType.refTypeId ||
        refsInEndEditor[refId].refType.type != refsInArticle[refId].refType.type ||
        refsInEndEditor[refId].ref_last_modified != refsInArticle[refId].ref_last_modified
      ) {
        refsThatViewsShouldBeUpdated[refId] = refsInArticle[refId]
      }
    })

    let idsOfRefsForUpdate = Object.keys(refsThatViewsShouldBeUpdated)
    if (idsOfRefsForUpdate.length > 0) {
      idsOfRefsForUpdate.forEach((key) => {
        refsInEndEditor[key] = refsInArticle[key]
      })
    }

    let deleatedRefsIds = Object.keys(deletedRefs)
    if (deleatedRefsIds.length > 0) {
      let newRefsInEdnEditor = {}
      Object.keys(refsInEndEditor).forEach((key) => {
        if (!deleatedRefsIds.includes(key)) {
          newRefsInEdnEditor[key] = refsInEndEditor[key]
        }
      })
      refsInEndEditor = newRefsInEdnEditor;
    }

    let refsWithNoFormControls = clearRefFromFormControl(refsInEndEditor)
    if(this.serviceShare.compareObjects(this.serviceShare.YdocService.referenceCitationsMap.get("referencesInEditor"), refsWithNoFormControls)) {
      this.serviceShare.YdocService.referenceCitationsMap.set('referencesInEditor', refsWithNoFormControls);
    }
        
    Object.keys(refsInEndEditor).forEach((key) => {
      refsInEndEditor[key].displayHTMLOriginal = refsInEndEditor[key].citation.bibliography;   
      refsInEndEditor[key].originalBibliography = refsInEndEditor[key].citation.textContent;
    })
    
    this.updateCitationsDisplayTextAndBibliography(refsInEndEditor)

    deleatedRefsIds.forEach((refId) => {
      this.removeBibliographyOfDeletedRef(refId);
    })

    if(Object.keys(refsInEndEditor).length == 0 && Object.keys(refsInArticle).length == 0) {
      this.removeBibliographyOfDeletedRef("", true);
    }
  }

  removeBibliographyOfDeletedRef(refId: string, deleteAll?: boolean) {
    let endEdView = this.serviceShare.ProsemirrorEditorsService!.editorContainers['endEditor'].editorView;

    let node: any;
    let from: any;
    let to: any;

    let state = endEdView.state;

    let docsize = state.doc.content.size
    let end_editor_refs_container
    let hasReferences = false;
    state.doc.nodesBetween(0, docsize - 1, (n, pos, parent, index) => {
      if(deleteAll) {
        hasReferences = true;
        if(n.type.name == 'reference_container') {
          end_editor_refs_container = n;
          to = n.nodeSize + 13;
        }
      } else {
        if (n.type.name == 'reference_citation_end' && n.attrs.referenceData.refId == refId) {
          node = n
          from = pos;
          to = n.nodeSize + pos;
        }else if(n.type.name == 'reference_container'){
          end_editor_refs_container = n
        }
      }
    })
    if (node) {
      if(end_editor_refs_container && end_editor_refs_container.content.childCount == 1){
        from = from - 15 // 19
        to = to+1
      } else if (deleteAll) {
        from = 4;
      }
      endEdView.dispatch(state.tr.deleteRange(from - 1, to + 1).setMeta('skipChange', true));
    } else if (deleteAll && hasReferences) {
      from = 0;
      endEdView.dispatch(state.tr.deleteRange(from, to).setMeta('skipChange', true));
    }
  }

  updateCitationsDisplayTextAndBibliography(refs: any) {
    let countObj: any = {}

    let updatedAnyDisplayText = false
    let updatedReferences: string[] = []
    // count number of refs with the same citation display text ex. : (Author 2022)

    Object.keys(refs).forEach((refId) => {
      let ref = refs[refId]
      let cictatDispTxt = ref.originalBibliography;
      if (!countObj[cictatDispTxt]) {
        countObj[cictatDispTxt] = 1;
      } else {
        countObj[cictatDispTxt]++;
      }
    })

    // change all citationDisplayText for all refs that have the same originalDisplayText

    Object.keys(countObj).forEach((text) => {
      if (countObj[text] > 1) {
        updatedAnyDisplayText = true
        let count = 1;
        Object.keys(refs).forEach((refId) => {
          let ref = refs[refId]
          if (ref.originalBibliography == text) {
            updatedReferences.push(refId)
            let char = String.fromCharCode(96 + count)
            let citationDisText = this.checkTextAndReplace(ref.originalBibliography, char)
            ref.citationDisplayText = citationDisText
            let bibliography = this.checkTextAndReplace(ref.displayHTMLOriginal, char)
            let html = this.checkTextAndReplace(ref.originalBibliography, char)
            ref.bibliography = bibliography
            ref.displayHTML = html
            count++;
          }
        })
      } else {
        updatedAnyDisplayText = true
        Object.keys(refs).forEach((refId) => {
          let ref = refs[refId]
          if (ref.originalBibliography == text) {
            updatedReferences.push(refId)
            ref.citationDisplayText = ref.originalBibliography
            ref.bibliography = ref.originalBibliography
            ref.displayHTML = ref.displayHTMLOriginal
          }
        })
      }

    })

    if (updatedAnyDisplayText) {      
      refs = this.serviceShare.CslService.sortCitations(refs);
      if(this.serviceShare.compareObjects(this.serviceShare.YdocService.referenceCitationsMap.get("refsAddedToArticle"), refs)) {
        this.serviceShare.YdocService.referenceCitationsMap.set('refsAddedToArticle', refs);
      }
      
      this.updateReferences(Object.values(refs));
      
      setTimeout(() => {
        this.updateReferenceCitats(updatedReferences, refs);

      }, 10)
    }
    this.checkIfShouldMarkRefsCitationsAsDeleted(refs)
    return refs
  }

  updateReferences(refs: any, newRefs?: any) {
    let view = this.serviceShare.ProsemirrorEditorsService!.editorContainers['endEditor'].editorView;
    let nodeStart: number = view.state.doc.nodeSize - 2    
    let nodeEnd: number;
    let state = view.state
    let schema: Schema = view.state.schema;

    if(!this.editRef && !newRefs) return;

    view.state.doc.forEach((node, offset, index) => {
      if (node.type.name == 'reference_container') {
        nodeStart = offset + node.nodeSize - 1
        nodeEnd = offset + node.nodeSize - 1
      }
    })

    let refTitle = schema.nodes.paragraph.create({ contenteditableNode: 'false' }, schema.text('References'))
    let h1 = schema.nodes.heading.create({ tagName: 'h1' }, refTitle)
    let nodesArr: Node[] = [];

    refs.forEach((ref: any) => {
      let referenceData = { refId: ref.ref.id, last_modified: ref.ref_last_modified };
      let referenceStyle = { name: ref.refStyle?.name, last_modified: ref.refStyle?.last_modified };
      let referenceType = { name: ref.refType?.name, last_modified: ref.refType?.last_modified };
      let recCitationAttrs = {
        contenteditableNode: 'false',
        refCitationID: uuidv4(),
        referenceData,
        referenceStyle,
        referenceType
      }
      
      let refNode = schema.nodes.reference_citation_end.create(recCitationAttrs, getHtmlInlineNodes(ref.citation.bibliography))
      
      let refContainerNode = schema.nodes.reference_block_container.create({ contenteditableNode: 'false' }, refNode)
      nodesArr.push(refContainerNode);
    })      
    let allRefsContainer = schema.nodes.reference_container.create({ contenteditableNode: 'false' }, nodesArr)
    let tr = state.tr.replaceWith(0, nodeEnd, [h1, allRefsContainer])
    view.dispatch(tr.setMeta('addToLastHistoryGroup', true))
    this.editRef = false;
  }

  checkIfShouldMarkRefsCitationsAsDeleted(refs: any) {
    Object.keys(this.serviceShare.ProsemirrorEditorsService!.editorContainers).forEach((sectionId) => {
      let edView = this.serviceShare.ProsemirrorEditorsService!.editorContainers[sectionId].editorView
      let allCitationsWithNonExistentRefs = this.getAllCitationsIdsWithNonExistantRefs(edView, refs)
      allCitationsWithNonExistentRefs.forEach((citeId) => {
        this.markRefCitationAsPointionToDeletedRef(edView, refs, citeId)
      })
    })
  }

  markRefCitationAsPointionToDeletedRef(view: EditorView, refs: any, citeId: string) {
    let edView = view;

    let node: any;
    let from: any;
    let to: any;
    let mark: Mark;

    let state = edView.state;
    let found = false;
    let docSize = state.doc.content.size
    
    state.doc.nodesBetween(0, docSize - 1, (n, p, par, i) => {
      const citationMark = n?.marks?.find(mark => mark.type.name == "reference_citation");
      if (citationMark && citationMark.attrs.refCitationID == citeId) {
        found = true
        node = n;
        from = p;
        to = n.nodeSize + p;
        mark = citationMark;
      }
    })
    if (found) {
      const attrs = JSON.parse(JSON.stringify(mark.attrs));
      attrs.citedRefsIds = ['pointing-to-deleted-ref']
      attrs.nonexistingelement = true;
      const citationMark = state.schema.marks.reference_citation.create(attrs);
      const citationTxt = state.schema.text(' Cited item deleted ', [citationMark]);
      edView.dispatch(state.tr.replaceWith(from, to, citationTxt).setMeta('addToLastHistoryGroup', true));
    }
  }

  getAllCitationsIdsWithNonExistantRefs(view: EditorView, refs: any) {
    let edView = view;
    let state = edView.state;
    let docSize = state.doc.content.size;

    let citaitonIds = [];
    state.doc.nodesBetween(0, docSize - 1, (n, p, par, i) => {
      const citationMark = n?.marks?.find(mark => mark.type.name == "reference_citation");
      if (citationMark && !citationMark.attrs.citedRefsIds.reduce((prev, curr) => { return (refs[curr] && prev) }, true) && (citationMark.attrs.nonexistingelement == false || citationMark.attrs.nonexistingelement == 'false')) {
        let citationId = citationMark.attrs.refCitationID;
        citaitonIds.push(citationId)
      }
    })
    
    return citaitonIds;
  }

  updateReferenceCitats(updatedReferences: string[], refs: any) {
    //update refs bibliography in end editor
    updatedReferences.forEach((refid) => {
      this.updateBibliography(refs, refid)
    })
    const referenceCitations = this.serviceShare.YdocService.referenceCitationsMap.get('referenceCitations');
    const cslCitationOptions = this.serviceShare.YdocService.articleData.layout.settings["allowed_tags"]?.cslCitationOptions || null;

    //update citation of refs
    Object.keys(this.serviceShare.ProsemirrorEditorsService!.editorContainers).forEach((sectionId) => {
      let edView = this.serviceShare.ProsemirrorEditorsService!.editorContainers[sectionId].editorView
      let allRefsCitationIdsInEditors = this.getAllCitationsIdsInEditor(edView)
      allRefsCitationIdsInEditors.forEach((citationId) => {
        this.updateRefsCitationsInEditor(edView, refs, citationId, referenceCitations, cslCitationOptions);
      })
    })
  }

  getAllCitationsIdsInEditor(view: EditorView) {
    const edView = view;
    const state = edView.state;
    const docSize = state.doc.content.size;

    const citaitonIds = []
    state.doc.nodesBetween(0, docSize - 1, (n, p, par, i) => {
      const citationMark = n?.marks?.find(mark => mark.type.name == "reference_citation");
      if (citationMark) {
        let citationId = citationMark.attrs.refCitationID;
        citaitonIds.push(citationId);
      }
    })
    return citaitonIds;
  }

  updateRefsCitationsInEditor(view: EditorView, refs: any, citationId: string, referenceCitations: any, cslCitationOptions: any) {
    let edView = view;

    let node: any;
    let from: any;
    let to: any;
    let mark: Mark;

    let state = edView.state;
    let found = false;
    let citatNewTxt: string;
    let docSize = state.doc.content.size;

    let allRefsIds = Object.keys(refs)
    state.doc.nodesBetween(0, docSize - 1, (n, p, par, i) => {
      const citationMark = n?.marks?.find(mark => mark.type.name == "reference_citation");
      if (
          citationMark &&
          citationMark.attrs.refCitationID == citationId && 
          citationMark.attrs.citedRefsIds.some((id: string) => allRefsIds.includes(id))
         ) {
        const citationTextContent = n.textContent;
        const citedReferences = {};

        Object.keys(refs).forEach((id: string) => {
          if(citationMark.attrs.citedRefsIds.includes(id)) {
            citedReferences[id] = refs[id];
          }
        });
        const selectedSortOptions = referenceCitations[citationMark.attrs.refCitationID]?.sortOptions ? referenceCitations[citationMark.attrs.refCitationID]?.sortOptions.map((opt: any) => opt.tag) : ["Default"];

        if(typeof referenceCitations[citationMark.attrs.refCitationID]?.citationLayout.layout == "string"){
          referenceCitations[citationMark.attrs.refCitationID].citationLayout.layout = [referenceCitations[citationMark.attrs.refCitationID].citationLayout.layout];
        }
        const layout = referenceCitations[citationMark.attrs.refCitationID]?.citationLayout?.layout ? referenceCitations[citationMark.attrs.refCitationID].citationLayout.layout.join("\n") : ["Default"].join("\n");
        
        const newCitationText = this.serviceShare.CslService.generateCitation(citedReferences, { selectedSortOptions, layout }).text;
        
        if (citationTextContent != newCitationText) {
          citatNewTxt = newCitationText;
          found = true
          node = n;
          from = p;
          to = n.nodeSize + p;
          mark = citationMark;
        }
      }
    })
    if (found) {
      let attrs = JSON.parse(JSON.stringify(mark.attrs));
      attrs.citedRefsIds = attrs.citedRefsIds.filter((id: string) => allRefsIds.includes(id));
      const citationMark = state.schema.marks.reference_citation.create(attrs);
      let citationTxt = state.schema.text(citatNewTxt, [citationMark]);
      edView.dispatch(state.tr.replaceWith(from, to, citationTxt).setMeta('addToLastHistoryGroup', true));
    }
  }

  updateBibliography(ydocRefs: any, refId: string) {
    let endEdView = this.serviceShare.ProsemirrorEditorsService!.editorContainers['endEditor'].editorView;

    let node: any;
    let from: any;
    let to: any;

    let state = endEdView.state;

    let docsize = state.doc.content.size
    state.doc.nodesBetween(0, docsize - 1, (n, pos, parent, index) => {
      if (n.type.name == 'reference_citation_end' && n.attrs.referenceData.refId == refId) {
        node = n
        from = pos;
        to = n.nodeSize + pos;
      }
    })
    if (node) {
      let attrs = JSON.parse(JSON.stringify(node.attrs));
      let newNode = state.schema.nodes.reference_citation_end.create(attrs, getHtmlInlineNodes(ydocRefs[refId].displayHTML))
      endEdView.dispatch(state.tr.replaceWith(from, to, newNode).setMeta('skipChange', true));
    }
  }

  checkIfAnyRefsShouldBeRemovedFromEndEditor(isVersionChange?: boolean) {
    let refsIdsInEndEditor: string[] = [];
    let view = this.serviceShare.ProsemirrorEditorsService!.editorContainers['endEditor'].editorView;
    let doc = view.state.doc
    let start = 0;
    let end = doc.content.size;

    const refsInYdoc = this.serviceShare.YdocService.referenceCitationsMap.get('refsAddedToArticle');
    const citedRefsInArticle = this.serviceShare.YdocService.referenceCitationsMap.get('citedRefsInArticle');

    if(isVersionChange && Object.keys(refsInYdoc).length == 0 && Object.keys(citedRefsInArticle).length == 0) {
      let positions = {from: 0, to: 0};
      doc.nodesBetween(start, end, (node, pos) => {
        if (node.type.name == 'reference_citation_end') {
          positions.to = pos + node.nodeSize;
        }
      })
      view.dispatch(view.state.tr.deleteRange(positions.from, positions.to));
      return;
    }
    

    doc.nodesBetween(start, end, (node) => {
      if (node.type.name == 'reference_citation_end') {
        if (!refsIdsInEndEditor.includes(node.attrs.referenceData.refId)) {
          refsIdsInEndEditor.push(node.attrs.referenceData.refId);
        } else {
          console.error('There are multilple references of one instance in the end editor.')
          this.removeRefFromEndEditorById(node.attrs.referenceData.refId)
        }
      }
    })
    let allcitedReferencesIdsInAllEditors: any[] = []

    Object.keys(this.serviceShare.ProsemirrorEditorsService!.editorContainers).forEach((sectionId) => {
      let view = this.serviceShare.ProsemirrorEditorsService!.editorContainers[sectionId].editorView;

      let doc = view.state.doc
      let start = 0;
      let end = doc.content.size;
      doc.nodesBetween(start, end, (node) => {
        const citationMark = node?.marks?.find(mark => mark.type.name == "reference_citation");
        if (citationMark) {
          citationMark.attrs.citedRefsIds.forEach((refId: string) => {
            if (!allcitedReferencesIdsInAllEditors.includes(refId)) {
              allcitedReferencesIdsInAllEditors.push(refId);
            }
          })
        }
      })
    })

      let refsIdsToRemoveFormEndEditor: string[] = refsIdsInEndEditor.filter(x => !allcitedReferencesIdsInAllEditors.includes(x));
      refsIdsToRemoveFormEndEditor.forEach((id) => {
        this.removeRefFromEndEditorById(id);
      })
  }

  removeRefFromEndEditorById(refId: string) {
    try {
      let endEditor = this.serviceShare.ProsemirrorEditorsService!.editorContainers['endEditor'];
      let view = endEditor.editorView;
      let st = view.state;
      let refsInEndEditor = this.serviceShare.YdocService!.referenceCitationsMap?.get('referencesInEditor')
      let nOfRefs = Object.keys(refsInEndEditor).length
      let from: any;
      let to: any;
      let docSize = st.doc.content.size
      let end_editor_refs_container:Node
      if (nOfRefs == 1) {
        st.doc.nodesBetween(0, docSize - 1, (n, p, par, i) => {
          if (n.type.name == 'reference_container') {
            from = p - 18;
            to = p + n.nodeSize
            end_editor_refs_container = n
          }
        })
      } else {
        st.doc.nodesBetween(0, docSize - 1, (n, p, par, i) => {
          if (n.type.name == 'reference_citation_end' && n.attrs.referenceData.refId == refId) {
            from = p - 1;
            to = p + n.nodeSize + 1
          }else if(n.type.name == 'reference_container'){
            end_editor_refs_container = n
          }
        })
      }

      if (from || to) {

        view.dispatch(
          st.tr.delete(from, to));
      }
      let refs = this.serviceShare.YdocService!.referenceCitationsMap?.get('referencesInEditor')
      let newRefs: any = {}
      Object.keys(refs).forEach((key) => {
        if (key != refId) {
          newRefs[key] = refs[key];
        }
      })

      let refsWithNoFormControls = clearRefFromFormControl(newRefs)
      if(this.serviceShare.compareObjects(this.serviceShare.YdocService.referenceCitationsMap.get("referencesInEditor"), refsWithNoFormControls)) {
        this.serviceShare.YdocService.referenceCitationsMap.set('referencesInEditor', refsWithNoFormControls);
      }
    } catch (err) { }
  }

  checkIfAnyRefsShouldBeAddedToEndEditor(olderVersionReferences?: any) {
    let allCitedRefs = this.serviceShare.YdocService!.referenceCitationsMap?.get('referencesInEditor');
    let refsInYdoc = this.serviceShare.YdocService.referenceCitationsMap.get('refsAddedToArticle');

    let refsInEndEditorKeys = Object.keys(allCitedRefs);
    let refsInArticleKeys = Object.keys(refsInYdoc);
    let newRefs = refsInArticleKeys.map((id: string, index: number) => {
      if(olderVersionReferences) {
        if(!Object.keys(olderVersionReferences).includes(id)) {
          return {
            index,
            id,
            added: true
          }
        } else if (Object.keys(olderVersionReferences).includes(id) && !refsInArticleKeys.includes(id)) {
          return {
            index,
            id,
            removed: true
          }
        } else {
          return {
            index,
            id,
          }
        }
      } else {
        if(!refsInEndEditorKeys.includes(id)) {
          return {
            index,
            id
          }
        }
      }
    }).filter(ref => ref != undefined);    

    if(!olderVersionReferences) {
      newRefs.forEach((ref) => {
        allCitedRefs[ref.id] = refsInYdoc[ref.id] || olderVersionReferences[ref.id];
      });
  
      let refsWithNoFormControls = clearRefFromFormControl(allCitedRefs);
      if(this.serviceShare.compareObjects(this.serviceShare.YdocService.referenceCitationsMap.get("referencesInEditor"), refsWithNoFormControls)) {
        this.serviceShare.YdocService.referenceCitationsMap.set('referencesInEditor', refsWithNoFormControls);
      }
      
      if(newRefs.length > 0) {
        this.addNewRefToEndEditor(newRefs, Object.values(refsInYdoc));
      }
    } else {
      newRefs.forEach((ref) => {
        allCitedRefs[ref.id] = refsInYdoc[ref.id] || olderVersionReferences[ref.id];
      });

      if(newRefs.length > 0) {
        this.addNewRefToEndEditor(newRefs, Object.values(allCitedRefs));
      }
    }

  }

  isAdded = false

  addNewRefToEndEditor(newRefs: { index: number, id: string, added?: boolean, removed?: boolean }[], refs: any[]) {
    let view = this.serviceShare.ProsemirrorEditorsService!.editorContainers['endEditor'].editorView;
    let nodeStart: number = view.state.doc.nodeSize - 2    
    let nodeEnd: number;
    let state = view.state
    let schema: Schema = view.state.schema;
    let reference_container: Node;
    if(this.editRef) return;
    view.state.doc.forEach((node, offset, index) => {
      if (node.type.name == 'reference_container') {
        reference_container = node;
      }
    })
    
    if(refs.length > 1 && newRefs.length == 1 && refs.length > reference_container.childCount) {
      let isFound = false;
      let pos: number;
      if(reference_container.childCount != refs.length) {
        newRefs.forEach(({index, id, removed, added}) => {
          reference_container.forEach((node, offset, i) => {
            if(i == index && node.firstChild.attrs.referenceData.refId != id) {
              isFound = true;
              let referenceData = { refId: refs[index].ref.id, last_modified: refs[index].ref_last_modified };
              let referenceStyle = { name: refs[index].refStyle.name, last_modified: refs[index].refStyle.last_modified };
              let referenceType = { name: refs[index].refType.name, last_modified: refs[index].refType.last_modified };
              let recCitationAttrs = {
                contenteditableNode: 'false',
                refCitationID: uuidv4(),
                referenceData,
                referenceStyle,
                referenceType,
              }
              let refNode = schema.nodes.reference_citation_end.create(recCitationAttrs, getHtmlInlineNodes(refs[index].citation.bibliography));
              let refContainerNode = schema.nodes.reference_block_container.create({ contenteditableNode: 'false' }, refNode);
              let tr = state.tr.insert(offset + 15, refContainerNode);
              view.dispatch(tr.setMeta('addToLastHistoryGroup', true))
            }
            pos = node.nodeSize + offset;
          })
        })
        if(!isFound) {
          const indexOfAdded = reference_container.childCount;
          let referenceData = { refId: refs[indexOfAdded].ref.id, last_modified: refs[indexOfAdded].ref_last_modified };
            let referenceStyle = { name: refs[indexOfAdded].refStyle.name, last_modified: refs[indexOfAdded].refStyle.last_modified };
            let referenceType = { name: refs[indexOfAdded].refType.name, last_modified: refs[indexOfAdded].refType.last_modified };
            let recCitationAttrs = {
              contenteditableNode: 'false',
              refCitationID: uuidv4(),
              referenceData,
              referenceStyle,
              referenceType
            }

            let refNode = schema.nodes.reference_citation_end.create(recCitationAttrs, getHtmlInlineNodes(refs[indexOfAdded].citation.bibliography));
            let refContainerNode = schema.nodes.reference_block_container.create({ contenteditableNode: 'false' }, refNode);
            let tr = state.tr.insert(pos + 15, refContainerNode);
            view.dispatch(tr.setMeta('addToLastHistoryGroup', true))
        }
      }
    } else if (refs.length == 1) {
      view.state.doc.forEach((node, offset, index) => {
        if (node.type.name == 'reference_container') {
          nodeStart = offset + node.nodeSize - 1
          nodeEnd = offset + node.nodeSize - 1
        }
      })
  
      let refTitle = schema.nodes.paragraph.create({ contenteditableNode: 'false' }, schema.text('References'))
      let h1 = schema.nodes.heading.create({ tagName: 'h1' }, refTitle)
      let nodesArr: Node[] = [];
  
      refs.forEach((ref: any) => {
        let referenceData = { refId: ref.ref.id, last_modified: ref.ref_last_modified };
        let referenceStyle = { name: ref.refStyle.name, last_modified: ref.refStyle.last_modified };
        let referenceType = { name: ref.refType.name, last_modified: ref.refType.last_modified };
        let recCitationAttrs = {
          contenteditableNode: 'false',
          refCitationID: uuidv4(),
          referenceData,
          referenceStyle,
          referenceType
        }
  
        let refNode = schema.nodes.reference_citation_end.create(recCitationAttrs, getHtmlInlineNodes(ref.citation.bibliography))
        let refContainerNode = schema.nodes.reference_block_container.create({ contenteditableNode: 'false' }, refNode)
        nodesArr.push(refContainerNode);
      })      
      let allRefsContainer = schema.nodes.reference_container.create({ contenteditableNode: 'false' }, nodesArr)
      let tr = state.tr.replaceWith(0, nodeEnd, [h1, allRefsContainer])
      view.dispatch(tr.setMeta('addToLastHistoryGroup', true))
    } else {
      this.updateReferences(refs, newRefs);
    }
  }

  checkTextAndReplace(text: string, char: string) {
    let result: any
    if (/[0-9]{4}\)/gm.test(text)) {
      let regResult = /[0-9]{4}\)/.exec(text)![0];
      result = text.replace(regResult, regResult.split(')')[0] + char + ')')
    } else if (/[0 - 9]{ 4 }/gm.test(text)) {
      let regResult = /[0 - 9]{ 4 }/.exec(text)![0];
      result = text.replace(regResult, regResult + char)
    } else if (/\([0-9]{4}\)/gm.test(text)) {
      let regResult = /\([0-9]{4}\)/.exec(text)![0];
      result = text.replace(regResult, regResult.split(')')[0] + char + ')')
    } else {
      result = char + '. ' + text;
    }
    return result
  }
}
