import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ySyncPluginKey } from '../../../y-prosemirror-src/plugins/keys.js';

import { editorContainer, ProsemirrorEditorsService } from '../../services/prosemirror-editors.service';
import { YdocService } from '../../services/ydoc.service';
import { figure } from '../../utils/interfaces/figureComponent';
import { ServiceShare } from '../../services/service-share.service';
import { createDocFromSnapshot, Doc, emptySnapshot } from 'yjs';

@Component({
  selector: 'app-figures-prosemirror-view',
  templateUrl: './figures-prosemirror-view.component.html',
  styleUrls: ['./figures-prosemirror-view.component.scss']
})
export class FiguresProsemirrorViewComponent implements AfterViewInit {

  @ViewChild('ProsemirrorEditor', { read: ElementRef }) ProsemirrorEditor?: ElementRef;
  @Input() figures!: figure[];
  endEditorContainer?:editorContainer

  @Input() versionData: {
    snapshot: any,
    prevSnapshot: any,
    userData
  } | undefined;

  constructor(
    private prosemirrEditorsService:ProsemirrorEditorsService,
    private ydocService:YdocService,
    private sharedService: ServiceShare
    ) {}

  ngAfterViewInit(): void {
    try{
      if (this.ydocService.editorIsBuild) {
        this.renderEndEditor()
      } else {
        this.ydocService.ydocStateObservable.subscribe(({event}) => {
          if (event == 'docIsBuild') {
            this.renderEndEditor()
          }
        });
      }
    }catch(e){
      console.error(e)
    }
  }

  renderEndEditor = ()=>{
    this.endEditorContainer = this.prosemirrEditorsService.renderDocumentEndEditor(this.ProsemirrorEditor?.nativeElement, undefined, this.versionData);
    if(this.versionData) {
      // setTimeout(() => {
      //   const editor = this.endEditorContainer.editorView;
      //   editor.dispatch(editor.state.tr.setMeta(ySyncPluginKey, {...this.versionData, userData: this.prosemirrEditorsService.userInfo}));
      //   setTimeout(() => {
      //     const olderVersionDoc = createDocFromSnapshot(this.ydocService.ydoc, this.versionData.prevSnapshot, new Doc({ gc: false }));
      //     const articleTablesOlderVersion = olderVersionDoc.getMap("ArticleTablesMap").get("ArticleTables") || {};
      //     const articleFiguresOlderVersion = olderVersionDoc.getMap("ArticleFiguresMap").get("ArticleFigures") || {};
      //     console.log(articleTablesOlderVersion, articleTablesOlderVersion);
          
      //     // console.log(olderVersionDoc.getMap("ArticleFiguresMap").get("ArticleFigures"));
      //     this.sharedService.updateCitableElementsViewsAndCites({
      //       table: articleTablesOlderVersion,
      //       figure: articleFiguresOlderVersion
      //     });
      //   }, 50);
      // }, 1000);
    }
  }
}
