import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { createDocFromSnapshot, decodeSnapshot } from 'yjs';
import {changeVersionSubject} from "../../../y-prosemirror-src/plugins/sync-plugin";

export enum VersionChange {
  RETURN_TO_NEWEST = 'returnToNewest',
  VERSION_CHANGE = 'versionChange',
  RERENDER = 'rerender',
  // INIT_ARTICLE_METADATA = "initArticleMetadata"
}

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit, AfterViewInit {

  @ViewChild("versionContainer", {read: ElementRef}) versionContainer: ElementRef;

  @Input() version: {date: Date, snapshot: any, index: number, users: any, restored?: Date, currentVersion?: boolean};
  @Input() versions: {date: Date, snapshot: any, index: number, users: any, restored?: Date, currentVersion?: boolean}[];
  usernames = [];
  currentVersionTime: number;

  constructor(
    public sharedService: ServiceShare, 
    private router: Router,
    private changeDetector: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    if(this.version.currentVersion) {
      this.currentVersionTime = new Date().getTime();

      const nextVersionChanges = this.sharedService.YdocService.ydoc.getMap("nextVersionChanges");
      this.usernames = nextVersionChanges.get("users") as any[] || [];

      nextVersionChanges.observe(() => {
        this.usernames = nextVersionChanges.get("users") as any[] || [];
        this.currentVersionTime = new Date().getTime();
        this.changeDetector.detectChanges();
      })
    }
  }

  ngAfterViewInit(): void {
    if(this.sharedService.YdocService.lastSelectedVersion == this.version.index) {
      this.versionContainer.nativeElement?.classList.add("selected");
      setTimeout(() => {
        (document.querySelector('.all-versions-container') as HTMLElement).scrollTop = this.versionContainer.nativeElement?.offsetTop;
      }, 100);
    } else {
      this.versionContainer.nativeElement?.classList.remove("selected");
    }
    if(this.version.currentVersion && !this.sharedService.oldVersion) {
      this.versionContainer.nativeElement?.classList.add("selected");
    }
    this.changeDetector.detectChanges();
  }

  selectVersion(version: {date: Date, snapshot: any, index: number, users: any}) {
    if(this.sharedService.YdocService.lastSelectedVersion != version.index) {
      (document.querySelector('.version-wrapper .selected') as HTMLElement)?.classList?.remove("selected");
      this.versionContainer.nativeElement?.classList.add("selected");
      this.sharedService.ProsemirrorEditorsService.spinSpinner();
      this.sharedService.YdocService.versionSubject.next("rerender");
      this.router.navigate([`${this.sharedService.YdocService.roomName.split("/")[0]}`], { fragment: `${version.index}` });
    }
  }

  restoreVersion(event: Event, version: {
    date: Date;
    snapshot: any;
    index: number;
    users: any;
    restored?: Date;
    currentVersion?: boolean;
}) {
    event.stopImmediatePropagation();
    this.sharedService.ProsemirrorEditorsService.spinSpinner();
    
    // this.sharedService.httpClient.post(`http://localhost:8080/api/article/${this.sharedService.YdocService.articleData.uuid}/restore/${version.index}`, {}).subscribe({
      this.sharedService.httpClient.post(`https://${this.sharedService.config.websocketHost}/api/article/${this.sharedService.YdocService.articleData.uuid}/restore/${version.index}`, {}).subscribe({
        next: (res: any) => {
        this.sharedService.YdocService.lastSelectedVersion = undefined;
        this.router.navigate([`${this.sharedService.YdocService.roomName.split("/")[0]}`]);
        this.sharedService.YdocService.versionSubject.next("reconnect");
        this.sharedService.YdocService.lastSelectedVersion = undefined;
      }, 
      error: (err: any) => {
        console.error(err);
      }
    })
  }
}
