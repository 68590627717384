import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EditSectionDialogComponent} from '../../../dialogs/edit-section-dialog/edit-section-dialog.component';
import {editorsThatShouldBeHiddenUntilContent, ProsemirrorEditorsService} from '../../../services/prosemirror-editors.service';
import {YdocService} from '../../../services/ydoc.service';
import {DetectFocusService} from '../../../utils/detectFocusPlugin/detect-focus.service';
import {articleSection, basicArticleSection} from '../../../utils/interfaces/articleSection';
import {TreeService} from '../../tree-service/tree.service';
import {DOMParser, Node} from 'prosemirror-model';
//@ts-ignore
import {updateYFragment} from '../../../../y-prosemirror-src/plugins/sync-plugin.js'
import {FormBuilderService} from '../../../services/form-builder.service';
import {UntypedFormGroup} from '@angular/forms';
import * as Y from 'yjs';
//@ts-ignore
import {AskBeforeDeleteComponent} from '@app/editor/dialogs/ask-before-delete/ask-before-delete.component';
import {ServiceShare} from '@app/editor/services/service-share.service';
import {
  getFilteredSectionChooseData,
  sectionChooseData,
  willBeMoreThan4Levels,
} from '@app/editor/utils/articleBasicStructure';
import {PmDialogSessionService} from '@app/editor/services/pm-dialog-session.service';
import {ChooseSectionComponent} from '@app/editor/dialogs/choose-section/choose-section.component';
import {material} from "@core/services/custom_sections/material";
import {treatmentSectionsSubsection} from "@core/services/custom_sections/tratment_sections_subsection";
import {treatmentSectionsCustom} from "@core/services/custom_sections/treatment_sections_description";
import {taxonSection} from "@core/services/custom_sections/taxon";
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { filterFieldsValues } from '@app/editor/utils/fieldsMenusAndScemasFns';
import { TextSelection } from 'prosemirror-state';

@Component({
  selector: 'app-section-leaf',
  templateUrl: './section-leaf.component.html',
  styleUrls: ['./section-leaf.component.scss']
})
export class SectionLeafComponent implements OnInit, AfterViewInit {

  @Input() parentListData!: { expandParentFunc: any, listDiv: HTMLDivElement,listinstance:SectionLeafComponent };
  @Input() parentId?: string; // the id of the parent of this node
  focusedId?: string;
  mouseOn?: string;

  canDropBool?: boolean[]

  previewMode
  expandIcon?: string;
  focusIdHold?: string;
  taxonomyData: any;

  getSection = (id: string) => this.ydocService.getSectionByID(id);

  @Input() node!: basicArticleSection;
  @Output() nodeChange = new EventEmitter<articleSection>();

  @Input() nodeFormGroup!: UntypedFormGroup;
  @Output() nodeFormGroupChange = new EventEmitter<UntypedFormGroup>();

  @Input() lastNestedChild!: boolean;
  @Input() nestedNode!: boolean;

  @Input() isComplex!: boolean;

  @Input() sectionsFormGroupsRef!: { [key: string]: UntypedFormGroup }
  @Output() sectionsFormGroupsRefChange = new EventEmitter<UntypedFormGroup>();


  @ViewChild('cdkDragSection', {read: ElementRef}) dragSection?: ElementRef;
  @ViewChild('childrenDiv', {read: ElementRef}) childrenDiv?: ElementRef;

  constructor(
    private formBuilderService: FormBuilderService,
    public treeService: TreeService,
    public ydocService: YdocService,
    private serviceShare: ServiceShare,
    public enforcer: EnforcerService,
    public detectFocusService: DetectFocusService,
    public prosemirrorEditorsService: ProsemirrorEditorsService,
    public PmDialogSessionService: PmDialogSessionService,
    private ref : ChangeDetectorRef,
    public dialog: MatDialog) {
    
    this.previewMode = prosemirrorEditorsService.previewArticleMode
    detectFocusService.getSubject().subscribe((focusedEditorId: any) => {
      if (focusedEditorId) {
        this.focusedId = focusedEditorId;
      }

      if (this.parentId !== 'parentList' && this.node.sectionID == this.focusedId) {
        (this.dragSection!.nativeElement as HTMLDivElement).scrollIntoView({behavior: 'smooth', block: 'center'})
        this.expandParentFunc();
      }
    });
  }

  ngAfterViewInit(): void {

  }


  ngOnInit() {
    this.expandIcon = 'chevron_right';
    this.canDropBool = this.treeService.canDropBool;
  }

  oldTextValue?: string

  editNodeHandle(node: articleSection, formGroup: UntypedFormGroup) {
    try {
      this.prosemirrorEditorsService.editMode = true;
      this.serviceShare.ProsemirrorEditorsService.spinSpinner();
      let defaultValues: any;
      if(!formGroup) {
        defaultValues = new UntypedFormGroup({});
      } else {
        defaultValues = formGroup.value;
      }
      filterFieldsValues( node.formIOSchema,{data:defaultValues},this.serviceShare,node.sectionID,true,'',false);
      //@ts-ignore
      node.children = this.node.children || [];
      const sectionContent = this.formBuilderService.populateDefaultValues(defaultValues, node.formIOSchema, node.sectionID, node, formGroup);
      let shouldDisableClose = false;
      if(node.modalTemplate) {
        shouldDisableClose = true;
      }
      if(node.title.name !== "[MM] Materials" && node.title.name !== "Material") {
        const updateYdoc = new Y.Doc({ gc: false });
        this.prosemirrorEditorsService.permanentUserData.setUserMapping(updateYdoc, updateYdoc.clientID, `${this.prosemirrorEditorsService.userInfo.data.name}&${this.prosemirrorEditorsService.userInfo.color.userColor}&${this.prosemirrorEditorsService.userInfo.data.id}&${this.prosemirrorEditorsService.userInfo.data.email}`);
        const maindocstate = Y.encodeStateAsUpdate(this.ydocService.ydoc);
        Y.applyUpdate(updateYdoc, maindocstate);
        const updateXmlFragment = updateYdoc.getXmlFragment(node.sectionID);
  
        const originUpdates: any[] = [];
        const registerUpdateFunc = (update: any) => {
          originUpdates.push(update)
        }

        this.ydocService.ydoc.on('update', registerUpdateFunc);

        node.formIOSchema = sectionContent
        this.dialog.open(EditSectionDialogComponent, {
          data: {node: node, form: formGroup, sectionContent},
          disableClose: shouldDisableClose
        }).afterClosed().subscribe(result => {
          if (result && result.compiledHtml && node.mode == 'documentMode') {
            if(node.formIOSchema.optional) {
              if((document.getElementsByClassName(node.sectionID)[0] as HTMLElement)?.style?.display == "none") {
                setTimeout(() => {
                  this.treeService.showHideSection(node.sectionID, "block");
                  this.treeService.treeVisibilityChange.next({ action: "showHideSection", sectionID: node.sectionID, value: "block", rerender: true });
                }, 200)
              }
            } else {
              this.treeService.editNodeChange(node.sectionID);
            }
            const copyOriginUpdatesBeforeReplace = [...originUpdates]
            const trackStatus = this.prosemirrorEditorsService.trackChangesMeta.trackTransactions
            this.prosemirrorEditorsService.trackChangesMeta.trackTransactions = false
            this.prosemirrorEditorsService.OnOffTrackingChangesShowTrackingSubject.next(
              this.prosemirrorEditorsService.trackChangesMeta
            )
            const templDiv = document.createElement('div');
            templDiv.innerHTML = result.compiledHtml
            const node1 = DOMParser.fromSchema(this.prosemirrorEditorsService.editorContainers[node.sectionID].editorView.state.schema).parse(templDiv.firstChild!);
            updateYFragment(updateYdoc, updateXmlFragment, node1, new Map());
            copyOriginUpdatesBeforeReplace.forEach((update) => {
              Y.applyUpdate(updateYdoc, update);
            })

            const maindocstate = Y.encodeStateAsUpdate(updateYdoc);
            Y.applyUpdate(this.ydocService.ydoc, maindocstate);
            this.prosemirrorEditorsService.permanentUserData.setUserMapping(this.ydocService.ydoc, this.ydocService.ydoc.clientID, `${this.prosemirrorEditorsService.userInfo.data.name}&${this.prosemirrorEditorsService.userInfo.color.userColor}&${this.prosemirrorEditorsService.userInfo.data.id}&${this.prosemirrorEditorsService.userInfo.data.email}`);

            setTimeout(() => {
              this.prosemirrorEditorsService.trackChangesMeta.trackTransactions = trackStatus
              this.prosemirrorEditorsService.OnOffTrackingChangesShowTrackingSubject.next(
                this.prosemirrorEditorsService.trackChangesMeta
                );
                setTimeout(()=>{
                // this.prosemirrorEditorsService.editMode = false;
                this.serviceShare.updateCitableElementsViews()
                updateYdoc?.destroy();
                this.ydocService.ydoc.off('update', registerUpdateFunc);              
                this.serviceShare.ProsemirrorEditorsService.stopSpinner();
                this.ydocService.articleSectionsMap.set(node.sectionID, node);
              },10);
            }, 30);
          } else {
            this.serviceShare.ProsemirrorEditorsService.stopSpinner();
            this.prosemirrorEditorsService.stopSpinner();
            this.ydocService.ydoc.off('update', registerUpdateFunc);
            this.prosemirrorEditorsService.editMode = false;
          }
        });
      } else {
        if(node.title.name == "[MM] Materials") {
          this.dialog.open(EditSectionDialogComponent, {
            data: {node: node, form: formGroup, sectionContent},
            disableClose: shouldDisableClose
          }).afterClosed().subscribe(() => {
            this.treeService.showHideSection(node.sectionID, "block");
            this.treeService.treeVisibilityChange.next({ action: "showHideSection", sectionID: node.sectionID, value: "block", rerender: true });
            this.serviceShare.ProsemirrorEditorsService.stopSpinner();
          });
        } else if (node.title.name == "Material") {
          this.dialog.open(EditSectionDialogComponent, {
            width: '95%',
            height: '90%',
            data: {node: node, form: formGroup, sectionContent},
            disableClose: shouldDisableClose
          }).afterClosed().subscribe((result: any) => {
            if(result && result.data) {
              this.treeService.editMaterial(node, result.data);
              this.treeService.treeVisibilityChange.next({ action: 'editNode', nodeId: node.sectionID, node, submission: result.data });
            }
            this.serviceShare.ProsemirrorEditorsService.stopSpinner();
          })
        }
       
      }
    } catch (e) {
      console.error(e);
    }
  }

  addNodeHandle(nodeId: string) {
    const articleSection = this.ydocService.getSectionByID(this.node.sectionID);
    if(!willBeMoreThan4Levels(this.treeService.getNodeLevel(this.node).nodeLevel, articleSection)){
      this.prosemirrorEditorsService.spinSpinner();
      this.treeService.addNodeChange(nodeId, articleSection.originalSectionTemplate, this.prosemirrorEditorsService.stopSpinner);
    }else{
      this.serviceShare.openSnackBar('Adding this subsection will exceed the maximum levels of the tree.','Close',()=>{},4000)
    }
    this.prosemirrorEditorsService.scrollTo(nodeId);
  }

  deleteNodeHandle(nodeId: string) {
    this.prosemirrorEditorsService.spinSpinner();
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      width: '563px',
      data: {objName: this.ydocService.getSectionByID(nodeId)?.title.label,type:'section'},
      panelClass: 'ask-before-delete-dialog',
    })
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.treeService.deleteNodeChange(nodeId, this.parentId!);
      }
      this.prosemirrorEditorsService.stopSpinner();
      this.prosemirrorEditorsService.scrollTo(this.parentId);
    })
  }
  oldIndex?:string
  // scrolledToView?: boolean

  scrollToProsemirror() {
    const articleSection = this.ydocService.getSectionByID(this.node.sectionID);

    if (articleSection.title.name == 'Material') {
      this.scrollToMaterial(articleSection);
    }

    if (articleSection.type == 'simple') {
      this.prosemirrorEditorsService.scrollTo(this.node.sectionID);
    } else if (articleSection.type == "complex") {
      this.prosemirrorEditorsService.scrollTo(this.node.sectionID);
    }
  }

  scrollToMaterial(articleSection: articleSection) {
    const parentSec = this.ydocService.getSectionByID(articleSection.originalSectionTemplate.parentId);
    const editorContainer = this.prosemirrorEditorsService.editorContainers[parentSec.sectionID];

    if (editorContainer) {
      const editorView = editorContainer.editorView;
      const { doc } = editorView.state;
      const materialNodePos = this.getPositionOfMaterialNode(doc, articleSection.sectionID);
      
      if (materialNodePos !== null) {
        const materialNodeSize = doc.nodeAt(materialNodePos).nodeSize;
        editorView.focus();
        editorView.dispatch(editorView.state.tr.scrollIntoView().setSelection(TextSelection.create(doc, materialNodePos + materialNodeSize - 1)));
      }
    }
  }

  getPositionOfMaterialNode(doc: Node, sectionId: string) {
    let foundPosition: number | null = null;
  
    const iterateNode = (node: Node, basePos: number) => {
      if (foundPosition !== null) return;
  
      node.content.forEach((child, offset) => {
        if (foundPosition !== null) return;
  
        if (
          child.type.name == 'ordered_list' ||
          child.type.name == 'bullet_list' ||
          child.type.name == 'list_item'
        ) {
          iterateNode(child, basePos + offset + 1);  // calculate node pos relative to the doc
        } else if (child.type.name == 'paragraph' && child.attrs.controlPath.includes(sectionId)) {
          foundPosition = basePos + offset;
        }
      });
    };
  
    iterateNode(doc, 0);
  
    return foundPosition;
  }

  changeDisplay(div: HTMLDivElement) {
    if (div.style.display == 'none') {
      div.style.display = 'block';
      this.expandIcon='expand_more'
    } else {
      div.style.display = 'none';
      this.expandIcon='chevron_right'
    }
    this.ref.detectChanges();
  }

  expandThisAndParentView = ()=>{
    this.expandParentFunc()
    if(this.childrenDiv && this.childrenDiv.nativeElement && this.childrenDiv.nativeElement.style.display == 'none'){
      this.changeDisplay(this.childrenDiv.nativeElement)
    }
  }

  expandParentFunc = () => {
    if (this.parentId !== 'parentList') {
      if (this.parentListData) {
        if (this.parentListData.listDiv.style.display == 'none') {
          this.parentListData.listDiv.style.display = 'block';
          this.parentListData.listinstance.expandIcon = 'expand_more'
          this.ref.detectChanges();
        }
        this.parentListData.expandParentFunc();
      }
    }
  };

  addSectionToNode(node: articleSection, formGroup: UntypedFormGroup) {
    this.prosemirrorEditorsService.spinSpinner()
    // if (node.title.name === '[MM] Materials') {
    //   material.parent = node;
    //   const materialData = JSON.parse(JSON.stringify(material));
    //   materialData.initialRender = this.serviceShare.YdocService.ydoc.guid
    //   materialData.active = true;
    //   materialData.defaultFormIOValues = {};
    //   let sectionContent = this.formBuilderService.populateDefaultValues({}, node.formIOSchema, node.sectionID,node, formGroup);
    //   this.prosemirrorEditorsService.stopSpinner()
    //   this.dialog.open(EditSectionDialogComponent, {
    //     data: {node: node, form: formGroup, sectionContent, component: '[MM] Material',editOnAddFromParent:true},
    //     disableClose: false
    //   }).afterClosed().subscribe(result => {
    //     if(result && result.data) {
    //       materialData.defaultFormIOValues = result.data;
    //       this.serviceShare.TreeService!.addNodeAtPlaceChange(node.sectionID, materialData, 'end');
    //     }
    //   });
    // } else if (['[MM] Description', '[MM] Diagnosis', '[MM] Distribution', '[MM] Ecology', '[MM] Conservation', '[MM] Biology', '[MM] Taxon discussion', '[MM] Notes', '[MM] Custom'].indexOf(node.title.name) > -1) {
    //   const treatmentSectionsSubsectionData = JSON.parse(JSON.stringify(treatmentSectionsSubsection));
    //   treatmentSectionsSubsectionData.parent = node;
    //   this.prosemirrorEditorsService.stopSpinner()
    //   this.serviceShare.TreeService!.addNodeAtPlaceChange(node.sectionID, treatmentSectionsSubsectionData, 'end');
    // } 
    //  else {
    //   taxonSection.parent = node;
    let { nodeLevel: sectionlevel } = this.treeService.getNodeLevel(node)

    const addNode = (result:sectionChooseData) => {
      if(result){
        if(result.source == 'template'){
          if(!willBeMoreThan4Levels(sectionlevel+1,result.template)){
            this.serviceShare.TreeService!.addNodeAtPlaceChange(node.sectionID, result.template, "end")
            this.expandThisAndParentView()
          }else{
            this.serviceShare.openSnackBar('Adding this subsection will exceed the maximum levels of the tree.','Close',()=>{},4000)
          }
          setTimeout(() => {
            this.prosemirrorEditorsService.stopSpinner();
          }, 500);
        }else{
          this.serviceShare.ArticleSectionsService!.getSectionById(result.id).subscribe((res: any) => {
            if(res.data.id == node.id && !res.data.pivot_id) {
              res.data.pivot_id = node.pivotId;
            }
            res.data.parent = node;
            if(!willBeMoreThan4Levels(sectionlevel+1,res.data)){
              this.serviceShare.TreeService!.addNodeAtPlaceChange(node.sectionID, res.data, "end")
              this.expandThisAndParentView();
            }else{
              this.serviceShare.openSnackBar('Adding this subsection will exceed the maximum levels of the tree.','Close',()=>{},4000)
            }
            setTimeout(() => {
              this.prosemirrorEditorsService.stopSpinner();
            }, 500);
          })
        }
      } else {
        this.prosemirrorEditorsService.stopSpinner();
      }
    }
    let fileredSections = getFilteredSectionChooseData(this.treeService.findNodeById(node.sectionID), this.treeService);

    if(fileredSections.length == 2 && fileredSections[0].id == fileredSections[1].id) {
      const section = fileredSections.find(sec => sec.source == "template");
      if(section) {
        addNode(section)
        return;
      }
    }

    if (fileredSections?.length === 1) {
      addNode(fileredSections[0])
      return;
    }
    const dialogRef = this.dialog.open(ChooseSectionComponent, {
      width: '563px',
      panelClass: 'choose-namuscript-dialog',
      data: {templates: fileredSections, sectionlevel:sectionlevel+1,node}
    });
    dialogRef.afterClosed().subscribe(addNode);
    // }
  }

  showButtons(div: HTMLDivElement, mouseOn: boolean, borderClass: string, focusClass: string, node: basicArticleSection) {
    const section = this.ydocService.getSectionByID(node.sectionID)
    /* if(mouseOn){
      div.style.zIndex = '4';
    }else{
      div.style.zIndex = '5';
    } */

    if (mouseOn) {
      this.mouseOn = node.sectionID;
    } else {
      this.mouseOn = '';
    }
    Array.from(div.children).forEach((el: any) => {
      if (el.classList.contains('section_btn_container')) {
        Array.from(el.children).forEach((el: HTMLButtonElement, index) => {
          if (el.classList.contains('hidden')) {
            if (mouseOn) {
              if (el.getAttribute('buttonaction') == 'add'&& this.treeService.showAddBtn(section)) { // add btn
                el.style.display = 'inline';
              } else if (el.getAttribute('buttonaction') == 'delete'&& this.treeService.showDeleteButton(section)) { // delete btn
                el.style.display = 'inline';
              } else if (el.getAttribute('buttonaction') == 'post_add'&& section.type == 'complex'&&this.treeService.showAddSubsectionBtn(node)) { // add subsection btn
                el.style.display = 'inline';
              } else if (el.getAttribute('buttonaction') == 'edit') {
                el.style.display = 'inline';
              }
            } else {
              el.style.display = 'none';
            }
          }
        });
      } else if (el.classList.contains('hidden')) {
        if (mouseOn) {
          el.style.display = 'inline';
        } else {
          el.style.display = 'none';
        }
      } else if (el.classList.contains('border')) {
        if (mouseOn) {
          if (this.focusedId == node.sectionID) {
            this.focusIdHold = node.sectionID;
            this.focusedId = '';
            /* el.classList.add(focusClass); */
          }
          el.className = `border ${borderClass} `;
          /* el.classList.remove(borderClass+"Inactive")
          el.classList.remove(borderClass)
          el.classList.add(borderClass)
          el.classList.remove(focusClass) */
          el.children.item(0).style.display = 'inline';
        } else {
          if (this.focusIdHold == node.sectionID) {
            this.focusedId = this.focusIdHold;
            this.focusIdHold = '';
          }
          el.className = `border ${borderClass}Inactive`;
          /* if(this.focusedId == this.node.sectionID){
            el.classList.add(focusClass);
          } */
          /* el.classList.remove(borderClass)
          el.classList.remove(borderClass)
          el.classList.add(borderClass+"Inactive") */
          el.children.item(0).style.display = 'none';
        }

      }
    });
  }

}
