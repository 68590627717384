import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Transaction, YMap, YMapEvent } from 'yjs/dist/src/internals';

import { YdocService } from '../../services/ydoc.service';
import { AddTableDialogComponent } from './add-table-dialog/add-table-dialog.component';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { citableTable } from '@app/editor/utils/interfaces/citableTables';
import { AskBeforeDeleteComponent } from '../ask-before-delete/ask-before-delete.component';
import { citationElementMap } from '@app/editor/services/citable-elements.service';

@Component({
  selector: 'app-citable-tables-dialog',
  templateUrl: './citable-tables-dialog.component.html',
  styleUrls: ['./citable-tables-dialog.component.scss']
})
export class CitableTablesDialogComponent implements OnDestroy {

  tablesMap?: YMap<any>;
  tablesNumbers?: string[];
  tables?: { [key: string]: citableTable };
  editedTables: { [key: string]: boolean } = {};
  deletedTables: string[] = [];

  observeFunc: (event: YMapEvent<any>, tr: Transaction) => void;

  constructor(
    private ydocService: YdocService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<CitableTablesDialogComponent>,
    private serviceShare: ServiceShare
  ) {
    this.serviceShare.ProsemirrorEditorsService.editMode = true;
    let tablesNumbersArray = this.ydocService.tablesMap!.get('ArticleTablesNumbers');
    this.tables = ydocService.tablesMap!.get('ArticleTables');
    this.tablesNumbers = JSON.parse(JSON.stringify(tablesNumbersArray));
    this.observeFunc = (event: YMapEvent<any>, tr: Transaction) => {
      let tablesNumbersArray = this.ydocService.tablesMap!.get('ArticleTablesNumbers');
      this.tables = this.ydocService.tablesMap!.get('ArticleTables');
      this.tablesNumbers = JSON.parse(JSON.stringify(tablesNumbersArray));
    }
    this.ydocService.tablesMap.observe(this.observeFunc);
  }

  drop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
      Object.keys(this.tables).forEach(key => {
        this.serviceShare.CitableElementsService.deletedTablesForRerender[key] = citationElementMap["table_citation"].getElFormIOSubmission(this.tables[key], undefined, this.serviceShare);
      })
      moveItemInArray(this.tablesNumbers!, event.previousIndex, event.currentIndex);
      this.serviceShare.CitableElementsService.writeElementDataGlobal( this.tables!, this.tablesNumbers!,'table_citation');
    }
  }

  editTable(table: citableTable, tableIndex: number) {
    this.dialog.open(AddTableDialogComponent, {
      width: '1000px',
      data: { table, updateOnSave: false, index: tableIndex, tableID: table.tableID },
      disableClose: this.serviceShare.CitableElementsService.closeOnClickOutsideTables
    }).afterClosed().subscribe((result: { table: citableTable }) => {
      if (result && result.table) {
        const editMode = result.table.editMode;
        this.tablesNumbers?.splice(tableIndex, 1, result.table.tableID);
        this.tables![result.table.tableID] = result.table;
        Object.keys(this.tables).forEach(key => {
          this.serviceShare.CitableElementsService.deletedTablesForRerender[key] = citationElementMap["table_citation"].getElFormIOSubmission(this.tables[key], undefined, this.serviceShare);
        });
        this.editedTables[result.table.tableID] = true;
        this.serviceShare.CitableElementsService.writeElementDataGlobal( this.tables!, this.tablesNumbers!,'table_citation', editMode);
      } else {
        this.serviceShare.ProsemirrorEditorsService.editMode = false;
      }
    })
  }

  deleteTable(table: citableTable, tableIndex: number) {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: { type: 'table', dontshowType:true,objName:'Table №'+(tableIndex+1) },
      panelClass: 'ask-before-delete-dialog',
    })
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.tablesNumbers?.splice(tableIndex, 1);
        delete this.tables![table.tableID];
        if (this.editedTables[table.tableID]) {
          delete this.editedTables[table.tableID];
        }
        this.serviceShare.YdocService.ydoc.getMap("change").set("change", "change");
        delete this.serviceShare.CitableElementsService.deletedTablesForRerender[table.tableID];
        this.serviceShare.CitableElementsService.writeElementDataGlobal( this.tables!, this.tablesNumbers!,'table_citation');
      }
    })
  }

  addTable() {
    this.dialog.open(AddTableDialogComponent, {
      width: '1000px',
      data: { fig: undefined, updateOnSave: false, index: this.tablesNumbers?.length },
      disableClose: this.serviceShare.CitableElementsService.closeOnClickOutsideTables
    }).afterClosed().subscribe((result: { table: citableTable }) => {
      if (result && result.table) {
        const hasTables = this.tablesNumbers.length > 0;
        this.tablesNumbers.push(result.table.tableID);
        result.table.tableNumber = this.tablesNumbers.length - 1;
        Object.keys(this.tables).forEach(key => {
          this.serviceShare.CitableElementsService.deletedTablesForRerender[key] = citationElementMap["table_citation"].getElFormIOSubmission(this.tables[key], undefined, this.serviceShare);
        });
        this.tables![result.table.tableID] = result.table;
        this.serviceShare.CitableElementsService.writeElementDataGlobal(this.tables!, this.tablesNumbers!,'table_citation');
        if(!hasTables) this.serviceShare.TreeService.metadatachangeMap.set('change', { guid: this.ydocService.ydoc.guid });
      } else {
        this.serviceShare.ProsemirrorEditorsService.editMode = false;
      }
    })
  }

  saveTables() {
    this.serviceShare.ProsemirrorEditorsService.editMode = false;
    this.dialogRef.close(true)
  }

  deleteAll() {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: {objName: "all", type:'tables'},
      panelClass: 'ask-before-delete-dialog',
      width:'300px !important'
    })
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.tablesNumbers = [];
        Object.keys(this.editedTables).forEach(tableID => {
          if (this.editedTables[tableID]) {
            delete this.editedTables[tableID];
          }
        })
        this.tables = {};
        this.serviceShare.CitableElementsService.writeElementDataGlobal(this.tables, this.tablesNumbers, 'table_citation');
      }
    })
  }

  cancelTablesEdit() {
    this.serviceShare.ProsemirrorEditorsService.editMode = false;
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.ydocService.tablesMap.unobserve(this.observeFunc);
  }
}

