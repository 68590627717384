import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Plugin, PluginKey, Selection } from 'prosemirror-state';
import {
  DecorationSet,
  EditorView,
  Decoration,
} from 'prosemirror-view';
import { undoInputRule, inputRules, InputRule } from 'prosemirror-inputrules';

import {
  AutocompleteTrMeta,
  FromTo,
  AutocompleteAction,
  AutocompleteState,
  Trigger,
  OpenAutocomplete,
  ActionKind,
  ActiveAutocompleteState,
  KEEP_OPEN,
} from './types';
import { AutocompleteModalComponent } from './autocomplete-modal/autocomplete-modal.component';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { RefsAddNewInArticleDialogComponent } from '@app/editor/dialogs/refs-add-new-in-article-dialog/refs-add-new-in-article-dialog.component';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  autocompletePluginKey = new PluginKey('citableElementsEditButtonsPlugin');
  autocompletePlugin: Plugin;

  inactiveAutocompleteState: AutocompleteState = {
    active: false,
    decorations: DecorationSet.empty,
  };

  picker = {
    view: null as EditorView | null,
    open: false,
    current: 0,
    range: null as FromTo | null,
  };

  triggers =  [
    { name: 'hashtag', trigger: "##",  cancelOnFirstSpace: true },
    { name: 'command', trigger: '//', cancelOnFirstSpace: false },
    { name: 'command', trigger: /(\/\/)\w+/, decorationAttrs: { class: 'command' } }
  ]

  getAutocompletePlugin: (reducer: any) => Plugin;

  constructor(
    private serviceShare: ServiceShare, 
    private dialog: MatDialog,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
    ) {
    const self = this;

    this.autocompletePlugin = new Plugin({
      key: self.autocompletePluginKey,
      view() {
        return {
          update: (view, prevState) => {
            const prev = self.autocompletePlugin.getState(prevState) as ActiveAutocompleteState;
            const next = self.autocompletePlugin.getState(view.state) as ActiveAutocompleteState;
  
            const started = !prev.active && next.active;
            const stopped = prev.active && !next.active;
            const changed = next.active && !started && !stopped && prev.filter !== next.filter;
  
            const action: Omit<AutocompleteAction, 'kind'> = {
              view,
              trigger: next.trigger ?? (prev.trigger as string),
              filter: next.filter ?? prev.filter,
              range: next.range ?? (prev.range as FromTo),
              type: next.type ?? prev.type,
            };
            if (started) self.reducer({ ...action, kind: ActionKind.open });
            if (changed) self.reducer({ ...action, kind: ActionKind.filter });
            if (stopped) self.reducer({ ...action, kind: ActionKind.close });
          },
          destroy: () => {
            self.hideAutocomplete();
          }
        };
      },
      state: {
        init: () => self.inactiveAutocompleteState,
        apply(tr, state): AutocompleteState {
          const meta = tr.getMeta(self.autocompletePlugin) as AutocompleteTrMeta;

          if (meta?.action === 'add') {
            const { trigger, filter, type } = meta;
            const from = tr.selection.from - trigger.length - (filter?.length ?? 0);
            const to = tr.selection.from;
            const className = type?.decorationAttrs?.class
              ? ['autocomplete', type?.decorationAttrs?.class].join(' ')
              : 'autocomplete';
            const attrs = { ...type?.decorationAttrs, class: className };
            const deco = Decoration.inline(from, to, attrs, {
              inclusiveStart: false,
              inclusiveEnd: true,
            });
            return {
              active: true,
              trigger: meta.trigger,
              decorations: DecorationSet.create(tr.doc, [deco]),
              filter: filter ?? '',
              range: { from, to },
              type,
            };
          }
          const { decorations } = state as AutocompleteState;
          const nextDecorations = decorations.map(tr.mapping, tr.doc);
          const hasDecoration = nextDecorations.find().length > 0;
          // If no decoration, explicitly remove, or click somewhere else in the editor
          if (
            meta?.action === 'remove' ||
            !self.inSuggestion(tr.selection, nextDecorations) ||
            !hasDecoration
          ) {
            return self.inactiveAutocompleteState;
          }
  
          const { active, trigger, type } = state as ActiveAutocompleteState;
          // Ensure that the trigger is in the decoration
          const { from, to } = nextDecorations.find()[0];
          const text = tr.doc.textBetween(from, to);
          if (!text.startsWith(trigger)) return self.inactiveAutocompleteState;
  
          return {
            active,
            trigger,
            decorations: nextDecorations,
            filter: text.slice(trigger.length),
            range: { from, to },
            type,
          };
        },
      },
      props: {
        handleDOMEvents:{
          'blur':(view,event)=>{
            //@ts-ignore
            if (event.relatedTarget && !event.relatedTarget.className?.includes("option")) {
              self.closeAutocomplete(view);
            }
          }
        },
        decorations: (state) => self.autocompletePlugin.getState(state)?.decorations,
        handlePaste: (view) => self.cancelIfInsideAndPass(view),
        handleDrop: (view) => self.cancelIfInsideAndPass(view),
        handleKeyDown(view, event) {
          event.stopPropagation();
          const { trigger, active, decorations, type } = self.autocompletePlugin.getState(
            view.state,
          ) as ActiveAutocompleteState;
  
          if (!active || !self.inSuggestion(view.state.selection, decorations)) return false;
  
          const { from, to } = decorations.find()[0];
          const text = view.state.doc.textBetween(from, to);
  
          // Be defensive, just in case the trigger doesn't exist
          const filter = text.slice(trigger?.length ?? 1);
  
          const checkCancelOnSpace = type?.cancelOnFirstSpace ?? true;
          if (
            checkCancelOnSpace &&
            filter.length === 0 &&
            (event.key === ' ' || event.key === 'Spacebar')
          ) {
            self.closeAutocomplete(view);
            // Take over the space creation so no other input rules are fired
            view.dispatch(view.state.tr.insertText(' ').scrollIntoView());
            return true;
          }
          if (filter.length === 0 && event.key === 'Backspace') {
            undoInputRule(view.state, view.dispatch);
            self.closeAutocomplete(view);
            return true;
          }
  
          const kind = self.actionFromEvent(event);
          const action: Omit<AutocompleteAction, 'kind'> = {
            view,
            trigger,
            filter,
            range: { from, to },
            type,
            event,
          };
          switch (kind) {
            case ActionKind.close:
              // The user action will be handled in the view code above
              // Allows clicking off to be handled in the same way
              return self.closeAutocomplete(view);
            case ActionKind.enter: {
              // Only trigger the cancel if it is not expliticly handled in the select
              const result = self.reducer({ ...action, kind: ActionKind.enter });
              if (result === KEEP_OPEN) return true;
              return result || self.closeAutocomplete(view);
            }
            case ActionKind.up:
            case ActionKind.down:
              return Boolean(self.reducer({ ...action, kind }));
            case ActionKind.left:
            case ActionKind.right:
              if (!type?.allArrowKeys) return false;
              return Boolean(self.reducer({ ...action, kind }));
            default:
              break;
          }
          return false;
        },
      }
    });
  }

  addInputRule() {
    return inputRules({
        rules: this.triggers.map((type) => this.createInputRule(this.autocompletePlugin, type)),
    })
  }

  createInputRule(plugin: Plugin, type: Trigger) {
    const trigger =
      typeof type.trigger === 'string'
        ? RegExp(`(?:^|\\s|\\n|[^\\d\\w])(${type.trigger.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})$`)
        : type.trigger;
  
    return new InputRule(trigger, (state, match) => {
      const { decorations } = plugin.getState(state);
      if (this.inSuggestion(state.selection, decorations)) return null;
      const tr = state.tr.insertText(match[1][match[1].length - 1]).scrollIntoView();
      const meta: OpenAutocomplete = { action: 'add', trigger: match[1], type };
      tr.setMeta(plugin, meta);
      return tr;
    });
  }

  inSuggestion(selection: Selection, decorations: DecorationSet) {
    return decorations.find(selection.from, selection.to).length > 0;
  }

  reducer(action: AutocompleteAction): any {
    const suggestion = document.querySelector('#suggestion') as HTMLDivElement;
    const autocomplete = document.querySelector('#autocomplete') as HTMLDivElement;
    // const NUM_SUGGESTIONS = suggestion.children.length;
    this.picker.view = action.view;

    const placeSuggestion = () => {
      suggestion.style.display = this.picker.open ? 'block' : 'none';
      const rect = document
        .getElementsByClassName('autocomplete')[0]
        ?.getBoundingClientRect();
      if (!rect) return;
      suggestion.style.top = `${rect.top + rect.height}px`;
      suggestion.style.left = `${rect.left}px`;

      this.searchTaxons(suggestion, action);

      // [].forEach.call(suggestion.children, (item: HTMLDivElement, i) => {
      //   item.classList[i === this.picker.current ? 'add' : 'remove']('selected');
      // });
    };

    const placeAutocomplete = () => {
      autocomplete.style.display = this.picker.open ? 'block' : 'none';
      const rect = document
        .getElementsByClassName('autocomplete')[0]
        ?.getBoundingClientRect();
        
      if (!rect) return;
      autocomplete.style.top = `${rect.top + rect.height}px`;
      autocomplete.style.left = `${rect.left}px`;
      
      if(action.kind != "filter") {
        this.autocompleteTaxons(autocomplete, action, false);
      } else if (action.kind == "filter") {
        this.autocompleteTaxons(autocomplete, action, true);
      }
      // switch(action.trigger) {
      //   // case "//ref": {
      //   //   this.searchRefs(autocomplete, action);
      //   //   break;
      //   // }
      //   // case "//" && action.trigger.length == 2: {
      //   //   this.autocompleteTaxons(autocomplete, action, false);
      //   //   break;
      //   // }
      //   case "//": {
      //     this.autocompleteTaxons(autocomplete, action, false);
      //     break;
      //   }
      // }
    }

    switch (action.kind) {
      case ActionKind.open:
        this.picker.current = 0;
        this.picker.open = true;
        this.picker.range = action.range;
        if(action.trigger == "##") {
          placeSuggestion();
        } else {
          placeAutocomplete();
        }
        return true;
      case ActionKind.close:
        this.picker.open = false;
        if(action.trigger == "##") {
          placeSuggestion();
        } else {
          placeAutocomplete();
        }
        return true;
      case ActionKind.filter:
        this.picker.open = true;
        placeAutocomplete();
      return true;
      // case ActionKind.up:
      //   this.picker.current -= 1;
      //   this.picker.current += NUM_SUGGESTIONS;
      //   this.picker.current %= NUM_SUGGESTIONS;
      //   if(action.trigger == "##") {
      //     placeSuggestion();
      //   } else {
      //     placeAutocomplete();
      //   }
      //   return true;
      // case ActionKind.down:
      //   this.picker.current += 1;
      //   this.picker.current %= NUM_SUGGESTIONS;
      //   if(action.trigger == "##") {
      //     placeSuggestion();
      //   } else {
      //     placeAutocomplete();
      //   }
      //   return true;
      // case ActionKind.enter: {
      //   const tr = action.view.state.tr
      //     .deleteRange(action.range.from, action.range.to)
      //     .insertText(
      //       `You can define this ${
      //         action.type ? `${action.type?.name} ` : ''
      //       }action!`
      //     );
      //   action.view.dispatch(tr);
      //   return true;
      // }
      default:
        return false;
    }
  }

  cancelIfInsideAndPass(view: EditorView) {
    const plugin = this.autocompletePluginKey.get(view.state) as Plugin;
    const { decorations } = plugin.getState(view.state);
    if (this.inSuggestion(view.state.selection, decorations)) {
      this.closeAutocomplete(view);
    }
    return false;
  }

  hideAutocomplete() {
    const suggestion = document.querySelector('#suggestion') as HTMLDivElement;
    const autocomplete = document.querySelector('#autocomplete') as HTMLDivElement;

    if(suggestion) {
      suggestion.style.display = "none";
    }
    if(autocomplete) {
      autocomplete.style.display = "none";
    }
  }

  closeAutocomplete(view: EditorView) {
    const plugin = this.autocompletePluginKey.get(view?.state) as Plugin;
    const meta: AutocompleteTrMeta = { action: 'remove' };
    const tr = view.state.tr.setMeta(plugin, meta);
    view.dispatch(tr);
    return true;
  }

  actionFromEvent(event: KeyboardEvent): ActionKind | null {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
        return event.key as ActionKind;
      case 'Tab':
      case 'Enter':
        return ActionKind.enter;
      case 'Escape':
        return ActionKind.close;
      default:
        return null;
    }
  }

  searchRefs(autocomplete: HTMLElement, action: AutocompleteAction) {
    const option = autocomplete.querySelector(".ref-citation-option") as HTMLElement;
    Array.from(autocomplete.children).forEach((ch: HTMLElement) => ch.style.display = "none");
    option.style.display = "block";
    option?.removeAllListeners("click");
    option.addEventListener("click", (e: Event) => {
      this.closeAutocomplete(action.view);
      const citationPos = action.range.from;
      const citationNodeSize = action.range.to - action.range.from;
      this.dialog.closeAll();
      this.dialog.open(RefsAddNewInArticleDialogComponent, {
        panelClass: ['editor-dialog-container', 'refs-add-new-in-article-dialog'],
        disableClose: !this.serviceShare.EditorsRefsManagerService.closeOnClickOutside
      }).afterClosed().subscribe((result: any) => {
        if (result && result instanceof Array) {
          let refs = {};
          result.forEach((refInstance) => {
            let refId = refInstance.ref.ref.id;
            let newRefs = this.serviceShare.YdocService.referenceCitationsMap.get("refsAddedToArticle");
            refs[refId] = refInstance.ref;
            newRefs[refId] = refInstance.ref;
            newRefs = this.serviceShare.CslService.sortCitations(newRefs);
            if(this.serviceShare.compareObjects(this.serviceShare.YdocService.referenceCitationsMap.get("refsAddedToArticle"), newRefs)) {
              this.serviceShare.YdocService.referenceCitationsMap.set('refsAddedToArticle', newRefs);
            }
          })

          const citation = {
            text: this.serviceShare.CslService.generateCitation(refs, {selectedSortOptions: [], layout: "Default"}).text,
            refCitationIDs: Object.keys(refs),
            citationLayout: 0,
            sortOptions: []
          }
  
          setTimeout(()=>{
            this.serviceShare.EditorsRefsManagerService.updateRefsInEndEditorAndTheirCitations();
            this.serviceShare.EditorsRefsManagerService.citateSelectedReferencesInEditor(citation, action.view, {citedRefsIds: [], citationNodeSize, citationPos, refCitationID: ""});
          },20)
        }
        option.style.display = "none";
      })
    })
  }

  searchTaxons(el: HTMLElement, action: AutocompleteAction, className?: string) {
    const taxonOption = el.querySelector(className || ".taxon") as HTMLElement;
    if(className) {
     Array.from(el.children).forEach((ch: HTMLElement) => ch.style.display = "none");
    }
    taxonOption.style.display = "block";
    taxonOption?.removeAllListeners("click");
    
    taxonOption.addEventListener("click", (event: Event) => {
      this.closeAutocomplete(action.view);

      this.dialog.closeAll();
      this.dialog.open(AutocompleteModalComponent, {
        width: "650px",
        data: { title: "Search Numenclature Citations"}
      }).afterClosed().subscribe((selectedData: string[]) => {
        if(selectedData) {
          const result = selectedData.join(", ");
          const { from, to } = action.range;
          
          action.view.dispatch(action.view.state.tr.replaceWith(from, to, action.view.state.schema.text(result)));

          setTimeout(() => {
            this.serviceShare.TaxonService.markTaxonsWithBackendService(result, action.view);
          }, 10);
        }
      })
    });
  }

  oldSub: Subscription;
  autocompleteTaxons(el: HTMLElement, action: AutocompleteAction, isTypping: boolean) {
    if(!isTypping) {
      el.innerHTML = `<div class="option"><h3>Type Taxon name</h3></div>`;
    } else {
      el.innerHTML = `<div class="option loader"><div class="lds-facebook"><div></div><div></div><div></div></div></div>`;

      if(this.oldSub) {
        this.oldSub.unsubscribe();
      }
      this.oldSub = this.httpClient.get(this.config.taxonSearch + `/taxons/${action.filter}`).pipe(debounceTime(500)).subscribe({
        next: (responce: any) => {
          el.innerHTML = "";
          if(responce.length > 0) {
            (responce as {scientificname: string, taxonrank: string}[]).forEach(taxon => {
              const btn = document.createElement("button");
              btn.className = "option taxon";
              btn.innerHTML = `<h3>${taxon.scientificname}</h3><span>Rank: ${taxon.taxonrank}</span>`;

              const clickHandler = (event: MouseEvent) => {
                const { from, to } = action.range;
                action.view.dispatch(action.view.state.tr.replaceWith(from, to, action.view.state.schema.text(taxon.scientificname)));
                //@ts-ignore
                // if(!action.view.isPopupEditor) {
                  setTimeout(() => {
                    this.serviceShare.TaxonService.markTaxonsWithBackendService(taxon.scientificname, action.view);
                  }, 10);
                // } else {

                // }
              }

              btn.addEventListener("click", clickHandler);
              el.append(btn);
            })
          } else {
            el.innerHTML = `<div class="option"><h3>No Results...</h3></div>`;
          }
        },
        error: (err: any) => {
         console.error(err);
         el.innerHTML = "There is ERROR from this search..."
        }
      })
    }
  }
}
