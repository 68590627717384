import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AllSelection, EditorState, Plugin, PluginKey, Selection, TextSelection } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { YMap } from 'yjs/dist/src/internals';
import { Mark, Node } from 'prosemirror-model';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { checkAllEditorsIfMarkOfCommentExists, commentData, commentYdocSave, ydocCommentsObj } from './commentMarksHelpers';
import { uuidv4 } from 'lib0/random';

export const commentMarkNames = [ "comment", "overlapComment", "overlapComment2", "overlapComment3", "overlapComment4", "overlapComment5", "overlapComment6", "overlapComment7", "overlapComment8", "overlapComment9", "overlapComment10" ];

export const articlePosOffset = 24;

// export let selInComment = (sel:Selection,node:Node,nodePos:number) =>{
//   let nodestart= nodePos;
//   let nodeend = nodePos+node.nodeSize;
//   return ((sel.from>nodestart&&sel.from<nodeend)||(sel.from>nodestart&&sel.from<nodeend))
// }

@Injectable({
  providedIn: 'root'
})

export class CommentsService {
  addCommentSubject
  commentsPlugin: Plugin
  commentPluginKey: PluginKey
  storeData: any;
  editorsOuterDiv?: HTMLDivElement
  commentsObject: any
  commentsVisibilityChange: Subject<any>
  addCommentData?: any = {}
  commentAllowdIn?: any = {} // editor id where comment can be made RN equals ''/undefined if there is no such editor RN
  selectedTextInEditors?: any = {} // selected text in every editor
  lastSelectedComments: { [key: string]: { commentId: string, commentMarkId: string, sectionId: string,pos:number } } = {}
  lastCommentSelected: {
    commentId?: string,
    pos?: number,
    sectionId?: string,
    commentMarkId?: string,
  }
  commentsMap?: YMap<commentYdocSave>
  lastSelectedCommentSubject: Subject<{
    commentId?: string,
    pos?: number,
    sectionId?: string,
    commentMarkId?: string
  }> = new Subject()

  shouldScrollComment = false;
  markIdOfScrollComment?:string = undefined
  commentsInYdoc: ydocCommentsObj = {}

  showResolved = false;
  self = this;

  selectedThreadComment = undefined;

  resetCommentsService() {
    this.storeData = undefined;
    this.editorsOuterDiv = undefined;
    this.addCommentData = {}
    this.commentAllowdIn = {} // editor id where comment can be made RN equals ''/undefined if there is no such editor RN
    this.selectedTextInEditors = {}
  }

  ydocCommentsChangeSubject: Subject<any> = new Subject()

  getCommentsFromYdoc():ydocCommentsObj{
    let commObj: ydocCommentsObj = {}
    Array.from((this.commentsMap?.keys() || [])).forEach((commentid) => {
      let comment = this.commentsMap.get(commentid)
      if (comment) {
        commObj[commentid] = comment
      }
    })
    return commObj
  }

  setYdocCommentsObj() {
    this.commentsInYdoc = this.getCommentsFromYdoc()
    this.serviceShare.YdocService.checkHiddenCommentsAndUsers();
    this.ydocCommentsChangeSubject.next(this.commentsInYdoc)
  }

  addCommentsMapChangeListener() {
    this.commentsMap = this.serviceShare.YdocService.getCommentsMap()
    this.setYdocCommentsObj()
    this.commentsMap.observe((ymapEvent, trnasact) => {
      this.setYdocCommentsObj()
    })
  }

  scrollToCommentMarkAndSelect(){
    let markid = this.markIdOfScrollComment;
    let edCont = this.serviceShare.ProsemirrorEditorsService.editorContainers

    let commentFound = false;
    let sectionId;
    let start;
    let end;

    Object.keys(edCont).forEach((sectionid)=>{
      let edDoc = edCont[sectionid].editorView.state.doc;
      let docSize = edDoc.content.size;
      edDoc.nodesBetween(0,docSize-1,(node,pos,parent,i)=>{
        if(node.marks.find((mark) => commentMarkNames.includes(mark.type.name) && mark.attrs.commentmarkid == markid) && !commentFound){
          commentFound = true;
          sectionId = sectionid;
          start = pos;
          end = pos+node.nodeSize
        }
      })
    })
    if(commentFound){
      setTimeout(()=>{
        let view = edCont[sectionId].editorView
        let state = view.state;
        let doc = state.doc
        view.focus()
        view.dispatch(state.tr.setSelection(TextSelection.between(doc.resolve(start),doc.resolve(end))));
        view.dispatch(view.state.tr.scrollIntoView())
      },100)
      return true;
    }else{
      return false;
    }
  }

  handleDeletedComments(deleted: any[]) {
    let filteredFromRepeatingMarks: string[] = []
    deleted.forEach((comAttrs) => {
      let commentId = comAttrs.attrs.id
      if (!filteredFromRepeatingMarks.includes(commentId)) {
        filteredFromRepeatingMarks.push(commentId)
      }
    })
    let edConts = this.serviceShare.ProsemirrorEditorsService.editorContainers;
    filteredFromRepeatingMarks.forEach((commentId) => {
      if (!checkAllEditorsIfMarkOfCommentExists(edConts, commentId)) {
        this.commentsMap.delete(commentId);
      }
    })
  }



  updateAllComments() {
    this.getCommentsInAllEditors()
  }

  updateTimestamp = 0;
  updateTimeout
  changeInEditors = () => {
    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
        this.updateAllComments();
    }, 500);
  }

  addInlineDecoration(state: EditorState, pos: number) {
    const node = state.doc.nodeAt(pos);
    if (!node) return;

    const comment = node.marks.find((mark) => commentMarkNames.includes(mark.type.name));

    if(!comment) return;
    if(comment.attrs.resolved == "true" && !this?.showResolved) return;

    let from: number;
    let to: number;

    const nodeSize = state.doc.content.size;
    state.doc.nodesBetween(0, nodeSize, (node, pos, parent, i) => {
      const mark2 = node?.marks.find(mark => mark.type.name == comment.type.name);
      if(mark2 && mark2.attrs.id == comment.attrs.id && !from) {
        from = pos;
      }
      if(mark2 && mark2.attrs.id == comment.attrs.id){
        to = pos + node.nodeSize;
      }
    })
    
    return { from, to };
  }

  constructor(private serviceShare: ServiceShare) {
    const self = this;

    this.lastSelectedCommentSubject.subscribe((data) => {
      this.lastCommentSelected.commentId = data.commentId
      this.lastCommentSelected.pos = data.pos
      this.lastCommentSelected.sectionId = data.sectionId
      this.lastCommentSelected.commentMarkId = data.commentMarkId
    })
    if (this.serviceShare.YdocService.editorIsBuild) {
      this.addCommentsMapChangeListener()
    } else {
      this.serviceShare.YdocService.ydocStateObservable.subscribe(({ event }) => {
        if (event == 'docIsBuild') {
          this.addCommentsMapChangeListener()
        }
      });
    }
    serviceShare.shareSelf('CommentsService', this)
    let addCommentSubject1 = new Subject<any>()
    this.addCommentSubject = addCommentSubject1
    this.addCommentSubject.subscribe((data) => {
      if (data.type == 'commentData') {
        this.addCommentData = data
      } else if (data.type == 'commentAllownes') {
        this.commentAllowdIn[data.sectionId] = data.allow

        this.selectedTextInEditors[data.sectionId] = data.text


      }
    })

    let commentPluginKey = new PluginKey('commentPlugin')
    this.commentPluginKey = commentPluginKey;

    let lastSelectedComments: { [key: string]: { commentId: string, commentMarkId: string, sectionId: string,pos:number } } = {}
    let lastCommentSelected: {
      commentId?: string,
      pos?: number,
      sectionId?: string,
      commentMarkId?: string,
    } = {}
    this.lastCommentSelected = lastCommentSelected
    this.lastSelectedComments = lastSelectedComments
    let setLastSelectedComment = this.setLastSelectedComment
    let changeInEditors = this.changeInEditors
    this.commentsPlugin = new Plugin({
      key: this.commentPluginKey,
      state: {
        init: (_:any, state) => {
          return { sectionName: _.sectionName };
        },
        apply(tr, prev, oldState, newState) {
          if(window.location.href.includes("#")) return  { ...prev, commentsStatus: { type: 'commentAllownes', sectionId: prev?.sectionName, allow: !true, text: "", errorMessage: "", err: true } };
          let { from, to, empty } = newState.selection;
          let err = false
          let text = newState.doc.textBetween(from, to)
          let commentableAttr = true
          let errorMessage = ''
          if (empty || from == to) {
            errorMessage = 'Selection is empty.'
            err = true
          }
          let selectedAComment = false;

          let sectionContainer = serviceShare.ProsemirrorEditorsService.editorContainers[prev.sectionName];
          let view = sectionContainer ? sectionContainer.editorView : undefined;

          if (!(newState.selection instanceof AllSelection) && view  && view.hasFocus()) {
            const pos = newState.selection.from;
            const node = newState.doc.nodeAt(newState.selection.from);

            if(node) {
              // const nodeBefore = newState.doc.resolve(pos - node?.textContent.length > 0 ? pos - node?.textContent.length : pos).nodeBefore;

              const comment = node.marks.find((mark) => commentMarkNames.includes(mark.type.name));

              if(comment) {
                if(self.selectedThreadComment) {
                  self.commentInSelection(node.marks.find((mark) => mark.attrs.id === self.selectedThreadComment), pos, prev.sectionName);
                  selectedAComment = true;
                } else {
                  const currUser = self.serviceShare.YdocService.currUser;
                  const collaborators = self.serviceShare.YdocService.collaborators.get("collaborators").collaborators.filter((c: any) => c.id != currUser.id);
                  let idsThatShouldBeHidden = collaborators.filter((c: any) => (c.hide_my_comments_from_user?.includes(currUser?.auth_role)) || c.hide_my_comments_from_user?.includes(currUser?.id)).map((c: any) => c.id);
                  if(self.serviceShare.hasOwnerCommentsPolicy) {
                    idsThatShouldBeHidden = collaborators.map((c: any) => c.id).filter((id: string) => id != currUser?.id);
                  }
                  const mark = node.marks.find((mark) => commentMarkNames.includes(mark.type.name) && !idsThatShouldBeHidden.includes(mark.attrs.userid));
                  if(mark) {
                    self.commentInSelection(mark, pos, prev.sectionName);
                    selectedAComment = true;
                  }
                }
              }
              // const overlapComment = node.marks.find((mark) => mark.type.name == 'overlapComment');

              // if(comment && overlapComment && nodeBefore) {
              //   const comment2 = nodeBefore?.marks.find((mark) => mark.type.name === 'comment');
              //   const overlapComment2 = nodeBefore?.marks.find((mark) => mark.type.name == 'overlapComment');

              //   if(comment2) {
                  // self.commentInSelection(comment2, pos, prev.sectionName);
                  // selectedAComment = true;
              //   } else if (overlapComment2) {
              //     self.commentInSelection(overlapComment2, pos, prev.sectionName);
              //     selectedAComment = true;
              //   } else {
              //     self.commentInSelection(comment, pos, prev.sectionName);
              //     selectedAComment = true;
              //   }
              // } else if (comment && !overlapComment) {
              //   self.commentInSelection(comment, pos, prev.sectionName);
              //   selectedAComment = true;
              // } else if (!comment && overlapComment){
              //   self.commentInSelection(overlapComment, pos, prev.sectionName);
              //   selectedAComment = true;
              // } else if (comment && overlapComment){
                // if(self.selectedThreadComment) {
                //   self.commentInSelection(node.marks.find((mark) => mark.attrs.id === self.selectedThreadComment), pos, prev.sectionName);
                // } else {
                //   self.commentInSelection(comment, pos, prev.sectionName);
                // }
                // selectedAComment = true;
              // }
            }
            
            let node1: Node;
            let node2: Node;
            if(node && from !== to) {
              node1 = newState.doc.nodeAt(from + 1);
              node2 = newState.doc.nodeAt(to - 1);
            }
            
            if(node1 && node2 && from !== to) {
              self.selectedThreadComment = undefined;
              const isFromCurrentUserMark = 
                node1.marks.filter(m => commentMarkNames.includes(m.type.name)).find(m => m.attrs.userid == self.serviceShare.YdocService.currUser.id) || 
                node2.marks.filter(m => commentMarkNames.includes(m.type.name)).find(m => m.attrs.userid == self.serviceShare.YdocService.currUser.id);
              
              if(node1.textContent == node2.textContent && isFromCurrentUserMark && isFromCurrentUserMark.attrs.resolved == "false") {
                err = true;
                errorMessage = "There is a comment here already";
              }
            //   if (commentMarks3.length && commentMarks4.length && commentMarks3[0].attrs.id == commentMarks4[0].attrs.id && isFromCurrentUser && commentMarks3[0].attrs.resolved == "false") {
            //     err = true;
            //     errorMessage = "There is a comment here already";
            //   }
            //   // if((commentMark1 || commentMark3) && (commentMark2 || commentMark4) || (commentMark1 || commentMark2) && (commentMark3 || commentMark4)) {
            //   //   err = true;
            //   //   errorMessage = "There is a comment here already";
            //   // }
              
              newState.doc.nodesBetween(from, to, (node, pos, parent) => {  
                const comMark = node?.marks.find(mark => commentMarkNames.includes(mark.type.name));
                if(comMark) {
                  self.commentInSelection(comMark, pos, prev.sectionName);
                selectedAComment = true;
                }
                if (node?.attrs.commentable == 'false') {
                  commentableAttr = false
                }
              })
            }
          }

          if (!commentableAttr && !err) {
            errorMessage = "You can't leave a comment there.";
            err = true;
          }

          if (!selectedAComment && !(newState.selection instanceof AllSelection) && view  && view.hasFocus() && lastCommentSelected.commentId) {
            setLastSelectedComment(undefined, undefined, undefined, undefined);
          }

          if (!(newState.selection instanceof AllSelection) /* && view.hasFocus() && tr.steps.length > 0 */) {
            changeInEditors();
          }
          let commentdata = { type: 'commentAllownes', sectionId: prev?.sectionName, allow: !err, text, errorMessage, err }
          addCommentSubject1.next(commentdata);

          return { ...prev, commentsStatus: commentdata };
        },
      },
      props: {
        decorations: (state: EditorState) => {
          const pluginState = this.commentPluginKey.getState(state);
          const focusedEditor = this.serviceShare.DetectFocusService.sectionName;
          const currentEditor = pluginState.sectionName;
          const { from } = state.selection;

          if (currentEditor != focusedEditor) return DecorationSet.empty;

          const markInfo = self.addInlineDecoration(state, from);
          if(!markInfo) return DecorationSet.empty;
          
          return DecorationSet.create(state.doc, [
            Decoration.inline(markInfo.from, markInfo.to, { class: 'active-comment' })
          ])
        }
      },
      view: function () {
        return {
          update: (view, prevState) => {
            if (JSON.stringify(view.state.doc) == JSON.stringify(prevState.doc) && !view.hasFocus()) {
              return;
            }
            let pluginData = commentPluginKey.getState(view.state)
            let editor = document.getElementsByClassName('editor-container').item(0) as HTMLDivElement
            let commentsStatus = pluginData.commentsStatus
            attachCommentBtn(editor, view, commentsStatus)
          },
          destroy: () => { }
        }
      },

    });
    let attachCommentBtn = (editor: HTMLDivElement, view: EditorView, commentsStatus: any) => {
      let { empty, from, to } = view.state.selection
      if(!editor) return;
      let commentBtnDiv = editor.getElementsByClassName('commentBtnDiv').item(0) as HTMLDivElement;
      let commentBtn = editor.getElementsByClassName('commentsBtn').item(0) as HTMLButtonElement;
      let editorBtnsWrapper = editor.getElementsByClassName('editor_buttons_wrapper').item(0) as HTMLDivElement;

      if (!view.hasFocus() || !commentBtnDiv) {
        return
      }
      let coordinatesAtFrom = view.coordsAtPos(from);
      let coordinatesAtTo = view.coordsAtPos(to);

      let averageValueTop = (coordinatesAtFrom.top + coordinatesAtTo.top) / 2; // Selected element position
      let editorOffsetTop = editor.getBoundingClientRect().top; // Editor Top offset in DOM
      let editorBtnsHeight = editorBtnsWrapper.offsetHeight; // Editor buttons dynamic height
      // TODO: Get line height of the selected element
      let currentElement = document.elementFromPoint(coordinatesAtFrom.right, coordinatesAtTo.top)
      
      let editorLineHeight = 0;
      if (currentElement) {
        editorLineHeight = parseInt(window.getComputedStyle(currentElement).lineHeight, 10);
      }
      editorBtnsWrapper.style.display = 'block'
      const top = (averageValueTop - editorOffsetTop + editor.scrollTop - editorBtnsHeight/2 + editorLineHeight/2);

      if(top < 0) {
        editorBtnsWrapper.style.top = "0px";
      } else {
        editorBtnsWrapper.style.top = top + "px";
      }
      
      editorBtnsWrapper.style.position = 'absolute';

      if (!commentsStatus.allow) {
        commentBtnDiv.style.display = 'none';
        return
      }
      commentBtnDiv.style.display = 'block';

      if(!commentBtn) return;
      commentBtn?.removeAllListeners!('click');
      let sectionName = commentPluginKey.getState(view.state).sectionName;

      commentBtn.addEventListener('click', () => {
        this.addCommentSubject.next({ type: 'commentData', sectionName, showBox: true })
        this.serviceShare.DetectFocusService.setSelectionDecorationOnLastSelecctedEditor()
        setTimeout(() => {
          this.getCommentsInAllEditors()
        }, 30)
      })
    }
  }

  commentInSelection = (actualMark: Mark, pos: number, sectionName: string) => {
    if(actualMark) {
      if(actualMark.attrs.resolved == "true" && !this.showResolved) {
        this.setLastSelectedComment(undefined, undefined, undefined, undefined);
        return;
      }
      if (this.sameAsLastSelectedComment(actualMark.attrs.id, pos, sectionName, actualMark.attrs.commentmarkid)) {
        return
      } else {
        this.setLastSelectedComment(actualMark.attrs.id, pos, sectionName, actualMark.attrs.commentmarkid)
        this.lastSelectedComments[actualMark.attrs.id] = {
          commentId: actualMark.attrs.id,
          commentMarkId: actualMark.attrs.commentmarkid,
          sectionId: sectionName,
          pos
        }
      }
    }
    setTimeout(() => {
      this.selectedThreadComment = undefined;
    }, 1000);
  }

  sameAsLastSelectedComment = (commentId?: string, pos?: number, sectionId?: string, commentMarkId?: string) => {
    if (
      this.lastCommentSelected.commentId != commentId ||
      this.lastCommentSelected.sectionId != sectionId ||
      this.lastCommentSelected.commentMarkId != commentMarkId ||
      this.lastCommentSelected.pos!=pos
    ) {
      return false;
    } else {
      return true;
    }
  }

  setLastSelectedComment = (commentId?: string, pos?: number, sectionId?: string, commentMarkId?: string,focus?:true) => {
    this.lastSelectedCommentSubject.next({ commentId, pos, sectionId, commentMarkId })
  }

  commentsObj: { [key: string]: commentData } = {}
  commentsChangeSubject: Subject<any> = new Subject()
  shouldCalc = false;

  getCommentsInAllEditors = () => {
    this.commentsObj = {}
    let edCont = this.serviceShare.ProsemirrorEditorsService.editorContainers
    Object.keys(edCont).forEach((sectionId) => {
      let view = edCont[sectionId].editorView;
      this.getComments(view, sectionId);
    })
    this.commentsChangeSubject.next('comments pos calc for all sections');
  }

  getComments = (view: EditorView, sectionId: string) => {
    let doc = view.state.doc
    let docSize: number = doc.content.size;
    doc.nodesBetween(0, docSize - 1, (node, pos, parent, index) => {
      const actualMark = node.marks.filter(mark => commentMarkNames.includes(mark.type.name));

      if (actualMark.length) {
        // should get the top position , the node document position , the section id of this view
        let articleElement = document.getElementById('app-article-element') as HTMLDivElement
        let articleElementRactangle = articleElement.getBoundingClientRect()
        let domCoords = view.coordsAtPos(pos)
        let markIsLastSelected = false

        let selComment = this.lastSelectedComments[actualMark[0].attrs.id];
        if (selComment) {
          if (!this.serviceShare.ProsemirrorEditorsService.editorContainers[selComment.sectionId]) {
            this.lastSelectedComments[actualMark[0].attrs.id] = undefined
          } else if (selComment.pos == pos&&selComment.commentId == actualMark[0].attrs.id && selComment.commentMarkId == actualMark[0].attrs.commentmarkid && selComment.sectionId == sectionId) {
            markIsLastSelected = true
          }
        }
        let lastSelected: true | undefined
        if (
          this.lastCommentSelected.commentId == actualMark[0].attrs.id &&
          this.lastCommentSelected.commentMarkId == actualMark[0].attrs.commentmarkid &&
          this.lastCommentSelected.sectionId == sectionId&&
          this.lastCommentSelected.pos == pos
        ) {
          lastSelected = true
        }
        
        if (markIsLastSelected || lastSelected || (!(markIsLastSelected || lastSelected) && !this.commentsObj[actualMark[0].attrs.id])) {
          const currUser = this.serviceShare.YdocService.currUser;
          const collaborators = this.serviceShare.YdocService.collaborators.get("collaborators").collaborators.filter((c: any) => c.id != currUser.id);
          let idsThatShouldBeHidden = collaborators.filter((c: any) => (c.hide_my_comments_from_user?.includes(currUser?.auth_role)) || c.hide_my_comments_from_user?.includes(currUser?.id)).map((c: any) => c.id);
          if(this.serviceShare.hasOwnerCommentsPolicy) {
            idsThatShouldBeHidden = collaborators.map((c: any) => c.id).filter((id: string) => id != currUser?.id);
          }
          if(!idsThatShouldBeHidden.includes(actualMark[0].attrs.userid)) {
            const { textContent, pmDocStartPos, pmDocEndPos, resolved } = this.getallCommentOccurrences(actualMark[0].attrs.id, view);
            this.commentsObj[actualMark[0].attrs.id] = {
              commentMarkId: actualMark[0].attrs.commentmarkid,
              pmDocStartPos,
              pmDocEndPos,
              section: sectionId,
              domTop: domCoords.top - articleElementRactangle.top-articlePosOffset,
              commentTxt: textContent,
              commentAttrs: actualMark[0].attrs,
              selected: markIsLastSelected,
              resolved,
              threadComments: []
            };
            actualMark.slice(1).forEach(mark => {
              if(!idsThatShouldBeHidden.includes(mark.attrs.userid)) {
                const { textContent, pmDocStartPos, pmDocEndPos, resolved } = this.getallCommentOccurrences(mark.attrs.id, view);
                if(this.commentsObj[actualMark[0].attrs.id].pmDocStartPos == pmDocStartPos && this.commentsObj[actualMark[0].attrs.id].pmDocEndPos == pmDocEndPos) {
                  this.commentsObj[actualMark[0].attrs.id].threadComments.push({
                    commentMarkId: mark.attrs.commentmarkid,
                    pmDocStartPos,
                    pmDocEndPos,
                    section: sectionId,
                    domTop: domCoords.top - articleElementRactangle.top-articlePosOffset,
                    commentTxt: textContent,
                    commentAttrs: mark.attrs,
                    selected: markIsLastSelected,
                    resolved,
                    threadComments: []
                  })
                } else {
                  this.commentsObj[mark.attrs.id] = {
                    commentMarkId: mark.attrs.commentmarkid,
                    pmDocStartPos,
                    pmDocEndPos,
                    section: sectionId,
                    domTop: domCoords.top - articleElementRactangle.top-articlePosOffset,
                    commentTxt: textContent,
                    commentAttrs: mark.attrs,
                    selected: markIsLastSelected,
                    resolved,
                    threadComments: []
                  }
                }
              }
            })
          } else {
            let m: Mark;
            actualMark.slice(1).forEach(mark => {
              if(!idsThatShouldBeHidden.includes(mark.attrs.userid)) {
                const { textContent, pmDocStartPos, pmDocEndPos, resolved } = this.getallCommentOccurrences(mark.attrs.id, view);
                if(m && this.commentsObj[m.attrs.id].pmDocStartPos == pmDocStartPos && this.commentsObj[m.attrs.id].pmDocEndPos == pmDocEndPos) {
                  this.commentsObj[m.attrs.id].threadComments.push({
                    commentMarkId: mark.attrs.commentmarkid,
                    pmDocStartPos,
                    pmDocEndPos,
                    section: sectionId,
                    domTop: domCoords.top - articleElementRactangle.top-articlePosOffset,
                    commentTxt: textContent,
                    commentAttrs: mark.attrs,
                    selected: markIsLastSelected,
                    resolved,
                    threadComments: []
                  })
                } else {
                  m = mark;
                  this.commentsObj[mark.attrs.id] = {
                    commentMarkId: mark.attrs.commentmarkid,
                    pmDocStartPos,
                    pmDocEndPos,
                    section: sectionId,
                    domTop: domCoords.top - articleElementRactangle.top-articlePosOffset,
                    commentTxt: textContent,
                    commentAttrs: mark.attrs,
                    selected: markIsLastSelected,
                    resolved,
                    threadComments: []
                  }
                }
              }
            })
          }
        }
      }
    });
  }

  getallCommentOccurrences(commentId: string, view: EditorView) {
    let nodeSize = view.state.doc.content.size;
    let textContent = '';
    let pmDocStartPos: number, pmDocEndPos: number;
    let resolved: string;

    view.state.doc.nodesBetween(0, nodeSize, (node: Node, pos: number) => {
      const actualMark = node.marks.find(mark => mark.attrs.id == commentId);
      if(actualMark) {
        textContent += node.textContent;
        // position = pos;
        resolved = actualMark.attrs.resolved
        if(!pmDocStartPos) {
          pmDocStartPos = pos;
          pmDocEndPos = pos + node.nodeSize;
        } else {
          pmDocEndPos += node.nodeSize;
        }
      }
    })

    return { textContent, pmDocStartPos, pmDocEndPos, resolved };
  }

  removeEditorComment(editorId: any) {
    this.commentsObject[editorId] = [];
    this.lastSelectedComments[editorId] = undefined;
  }

  // init() {
  //   this.editorsOuterDiv = document.getElementsByClassName('editor')[0] as HTMLDivElement
  // }

  getCommentsIds(comment: commentData) {
    return [comment.commentAttrs.id, ...comment.threadComments.map(c => c.commentAttrs.id)].join(" ");
  }

  areAllResolved(comment: commentData) {
    return comment.commentAttrs.resolved == "true" && !comment.threadComments.find(c => c.commentAttrs.resolved == "false");
  }

  getPlugin(): Plugin {
    return this.commentsPlugin
  }

}

