import { Node } from "prosemirror-model";
const comment = {
    attrs: {
        class: { default: 'comment' },
        id: { default: '' },
        group: { default: '' },
        viewid: { default: '' },
        conversation: { default: [] },
        userColor : { default : '' },
        userContrastColor: { default: '' },
        date: { default: '' },
        userid: { default: '' },
        username: { default: '' },
        commentmarkid: { default: '' },
        resolved: { default: "false" }
    },
    inclusive: false,
    excludes: '',
    parseDOM: [{
        tag: 'span.comment',
        getAttrs(dom:any) {
            return {
                class: dom.getAttribute('class'),
                id: dom.dataset.id,
                group: dom.dataset.group,
                userContrastColor: dom.getAttribute('usercontrastcolor'),
                userColor: dom.getAttribute('usercolor'),
                viewid: dom.dataset.viewid,
                date: dom.dataset.date,
                userid: dom.dataset.userid,
                username: dom.dataset.username,
                commentmarkid: dom.dataset.commentmarkid,
                resolved: dom.getAttribute('resolved')
            }
        },
    }],
    toDOM(node:Node) {
        return [
            'span',
            {
                class: node.attrs.class,
                'data-id': node.attrs.id,
                'data-conversation': JSON.stringify(node.attrs.conversation),
                'data-viewid': node.attrs.viewid,
                'usercontrastcolor': node.attrs.userContrastColor,
                'data-group': node.attrs.group,
                'usercolor': node.attrs.userColor,
                'data-date': node.attrs.date,
                'data-userid': node.attrs.userid,
                'data-username': node.attrs.username,
                'data-commentmarkid': node.attrs.commentmarkid,
                resolved: node.attrs.resolved,
                style: '--tooltip-background-color: ' + node.attrs.userColor + ";--tooltip-color: " + node.attrs.userContrastColor,
            },
            0
        ];
    },
};


const overlapComment = {
    attrs: {
        class: { default: 'comment' },
        id: { default: '' },
        group: { default: '' },
        viewid: { default: '' },
        conversation: { default: [] },
        userColor : {default : ''},
        userContrastColor: {default:''},
        date:{default:''},
        userid:{default:''},
        username:{default:''},
        commentmarkid:{default:''},
        resolved: { default: "false" }
    },
    inclusive: false,
    excludes: '',
    parseDOM: [{
        tag: 'span.comment',
        getAttrs(dom:any) {
            return {
                class: dom.getAttribute('class'),
                id: dom.dataset.id,
                group: dom.dataset.group,
                userContrastColor:dom.getAttribute('usercontrastcolor'),
                userColor: dom.getAttribute('usercolor'),
                viewid: dom.dataset.viewid,
                date: dom.dataset.date,
                userid: dom.dataset.userid,
                username: dom.dataset.username,
                commentmarkid: dom.dataset.commentmarkid,
                resolved: dom.getAttribute('resolved')
            }
        },
    }],
    toDOM(node:Node) {
        return[
            'span',
            {
                class: node.attrs.class,
                'data-id': node.attrs.id,
                'data-conversation': JSON.stringify(node.attrs.conversation),
                'data-viewid': node.attrs.viewid,
                'usercontrastcolor': node.attrs.userContrastColor,
                'data-group': node.attrs.group,
                'usercolor': node.attrs.userColor,
                'data-date': node.attrs.date,
                'data-userid': node.attrs.userid,
                'data-username': node.attrs.username,
                'data-commentmarkid': node.attrs.commentmarkid,
                resolved: node.attrs.resolved,
                style: '--tooltip-background-color: ' + node.attrs.userColor + ";--tooltip-color: " + node.attrs.userContrastColor,
            },
        ];
    },
}

const overlapComment2 = overlapComment;
const overlapComment3 = overlapComment;
const overlapComment4 = overlapComment;
const overlapComment5 = overlapComment;
const overlapComment6 = overlapComment;
const overlapComment7 = overlapComment;
const overlapComment8 = overlapComment;
const overlapComment9 = overlapComment;
const overlapComment10 = overlapComment;

export { comment, overlapComment, overlapComment2, overlapComment3, overlapComment4, overlapComment5, overlapComment6, overlapComment7, overlapComment8, overlapComment9, overlapComment10 };