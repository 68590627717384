import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ServiceShare } from '../services/service-share.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-versions-section',
  templateUrl: './versions-section.component.html',
  styleUrls: ['./versions-section.component.scss'],
})
export class VersionsSectionComponent implements AfterViewInit, OnDestroy {

  versions = [];

  searchControl = new UntypedFormControl('');

  observeFunc: () => void;

  subscribtion = new Subscription();

  @ViewChild('versionsInput', { read: ElementRef }) versionsInput?: ElementRef;

  constructor(private sharedService: ServiceShare, private changeDetector: ChangeDetectorRef) {
    this.observeFunc = () => {
      if(!this.searchControl.value.trim()) {
        this.getVersions();
        this.changeDetector.detectChanges();
      }
    };

    this.sharedService.YdocService.articleVersions.observe(this.observeFunc);
    this.getVersions();
  }

  ngAfterViewInit(): void {
    this.subscribtion.add(this.searchControl.valueChanges.subscribe((value: string) => {
      const num = Number(value);
      
      if(value && value.trim() && num) {
        this.getVersions(num);
      } else {
        this.getVersions();
      }
      this.changeDetector.detectChanges();
    }));
    this.subscribtion.add(this.sharedService.YdocService.allowedVersionsStateSubject.subscribe(() => {
      this.getVersions();
      this.changeDetector.detectChanges();
    }))
    this.changeDetector.detectChanges();
  }

  getVersions(index?: number) {
    let versions: any[] = [];
    const articleVersions = this.sharedService.YdocService.articleVersions.toArray();
    const hasLatest = this.sharedService.YdocService.currUser.allowed_article_versions.includes("latest");
    const allowedVersions = this.sharedService.YdocService.currUser.allowed_article_versions.filter((v: string) => v != "latest").map((n: string) => +n);
    // const lastVersion = articleVersions[articleVersions.length - 1];

    articleVersions.forEach((version: any, index: number) => {
      if(hasLatest && !allowedVersions.length) return;
      if(allowedVersions.length && !allowedVersions.includes(index)) return;

      if(version.restored) {
        const restoredDate = new Date(version.restored).toISOString();
        versions.unshift({date: new Date(version.date).toISOString(), snapshot: version.snapshot, index, users: version.users, restored: restoredDate});
      } else {
        versions.unshift({date: new Date(version.date).toISOString(), snapshot: version.snapshot, index, users: version.users});
      }
    });

    // if (hasLatest && !versions.find(v => v.index == (articleVersions.length - 1))) {
    //   versions.unshift({date: new Date(lastVersion.date).toISOString(), snapshot: lastVersion.snapshot, index: articleVersions.length - 1 || 1, users: lastVersion.users });
    // }

    if (hasLatest || !allowedVersions.length) {
      const name = this.sharedService.ProsemirrorEditorsService.userInfo.data.name;
      const id = this.sharedService.ProsemirrorEditorsService.userInfo.data.id;
      const userColor = this.sharedService.ProsemirrorEditorsService.userInfo.color.userColor;
      versions.unshift({date: new Date().toISOString(), snapshot: undefined, index: versions.length || 1, users: [{name, id, userColor} ], currentVersion: true});
    }

    this.sharedService.onlyOldVersions = !!allowedVersions.length && !hasLatest;

    if(!isNaN(index)) {
      this.versions = versions[index] ? [versions.reverse()[index]] : [];
    } else {
      this.versions = versions;
    }
  }

  ngOnDestroy(): void {
    this.sharedService.YdocService.articleVersions.unobserve(this.observeFunc);
    this.subscribtion.unsubscribe();
  }
}
