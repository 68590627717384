import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable } from 'rxjs';
import { mergeMap, shareReplay, tap } from 'rxjs/operators';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { AuthService } from '@core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CasbinGuard implements CanActivate {

  constructor(
    private router: Router,
    private sharedService: ServiceShare,
    private _snackBar: MatSnackBar,
    private readonly authService: AuthService
    ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      route.pathFromRoot.length>2&&
      route.pathFromRoot[2].routeConfig.path == ':id'
      ) {
      return from(new Promise<boolean>((resolve, reject) => {
        let articleId = route.params.id;
        let userData: any;
        this.sharedService.AuthService.currentUser$.subscribe({
          next:(data)=>{
            userData = data
            let articleByIdRequest = this.sharedService.ArticlesService.replayObservable ? this.sharedService.ArticlesService.replayObservable : this.sharedService.ArticlesService?.getArticleByUuid(articleId).pipe(shareReplay());
            articleByIdRequest.pipe(
              mergeMap((res: any) => {
                if (res.status == 404) {
                  this.router.navigate(['404']);
                  resolve(true);
                } else {
                  this.sharedService.EnforcerService.enforceAsync('is-admin','admin-can-do-anything').subscribe((admin)=>{
                    let currUserId = userData.id;
                    let collaborators:{user_id:string,type:string}[] = res.data.collaborators;
                    if(collaborators.some((user)=>user.user_id == currUserId)||res.data.user.id == currUserId){
                      resolve(true);
                    }else{
                      resolve(false);
                    }
                    // if(admin){
                    //   resolve(true);
                    // }else{
                    //   let currUserId = userData.id;
                    //   let collaborators:{user_id:string,type:string}[] = res.data.collaborators;
                    //   if(collaborators.some((user)=>user.user_id == currUserId)||res.data.user.id == currUserId){
                    //     resolve(true);
                    //   }else{
                    //     resolve(false);
                    //   }
                    // }
                  })
                }
                return this.sharedService.ArticlesService.getArticleDomainPolicies(articleId);
              })
            ).subscribe({
              next: (res: any) => {
                this.sharedService.EnforcerService.policiesChangeSubject.next(res);
              }, 
              error: (error) => {
                if(error.status == 404) {
                  this.router.navigate(['404']);
                  resolve(true);
                } else {
                  this.router.navigate(['404']);
                  console.error(error);
                  resolve(true);
                }
              }
            });

            this.sharedService.addResolverData('CasbinResolver',articleByIdRequest);
          },
          error:(err)=>{
            this.router.navigate(['404']);
            resolve(true);
          }
        })
        })).pipe(tap((x)=>{
          if (!x && route.pathFromRoot[2].routeConfig.path == ":id") {
            this._snackBar.open("You don't have permission and cannot access this information or do this action.",'Ok', {
              duration: 3* 1000
            });
            this.router.navigate(['dashboard']);
          }
        }));
    } else if (
      route.pathFromRoot.length>2&&
      route.pathFromRoot[2].routeConfig.path == 'create'
      ) {
      return from(new Promise<boolean>((resolve, reject) => {
        this.sharedService.EnforcerService.enforceAsync('is-admin','admin-can-do-anything').subscribe((admin)=>{
        if(admin){
          resolve(true);
        } else {
          this.sharedService.EnforcerService.enforceAsync('/layouts','POST').subscribe((canCreateArticle)=>{
            if(canCreateArticle){
              resolve(true);
            }else{
              resolve(false);
            }
          })
        }
        })
        })
      ).pipe(tap((x)=>{
          if (!x) {
            this._snackBar.open("You don't have permission and cannot access this information or do this action.",'Ok', {
              duration: 3 * 1000
            });
            this.router.navigate(['dashboard'])
          }
        }));
    }
    return Promise.resolve(true);
  }

}
